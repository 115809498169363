const GetVerificationPopupMsg = (userData) => {
    let msg = '';
    if (!userData.emailVerified && !userData.mobileVerified) {
        msg = 'Please verify your email address and mobile number to proceed further';
    } else if (userData.emailVerified && !userData.mobileVerified) {
        msg = 'Please verify your mobile number to proceed further';
    } else if (!userData.emailVerified && userData.mobileVerified) {
        msg = 'Please verify your email address to proceed further';
    }
    return msg;
};

const VerificationPopupFields = {
    title: 'Warning',
    okText: 'Ok',
    cancelText: '',
};

export { VerificationPopupFields, GetVerificationPopupMsg };
