import { find } from 'lodash';

import { useMeQuery } from '.';

const useAuthenticated = () => {
    const { data, loading } = useMeQuery();

    if (loading) return [false, false];

    const isAuthenticated = data && data.me ? true : false;
    const isAdmin = isAuthenticated && find(data.me.roles, { type: 'ADMIN' }) !== undefined;
    const userData = data && data.me ? data.me : {};

    return [isAuthenticated, isAdmin, userData];
};

export { useAuthenticated };
