import { Circle, GoogleMap, withGoogleMap } from 'react-google-maps';

import { getZoom } from './getZoom';

type LatLng = {
    lat: number;
    lng: number;
};

type Props = {
    center?: LatLng;
    distance: number;
};

const Map = withGoogleMap<Props>(({ center, distance }) => {
    return (
        <GoogleMap
            center={center}
            defaultOptions={{
                draggable: false,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
            }}
            zoom={getZoom(distance)}
        >
            {distance !== 0 && <Circle center={center} radius={distance * 1609.344} />}
        </GoogleMap>
    );
});

export { Map };
