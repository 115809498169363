import { CalendarOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Avatar, Col, List, Row, Space, Tag } from 'antd';
import moment from 'moment';

import { Card } from '@/components/atoms';

const AdminSosListDetails = ({ values }) => {
    return (
        <Row gutter={[0, 32]}>
            <Col span={24}>
                <Card
                    title={
                        <Space>
                            <CalendarOutlined /> Details
                        </Space>
                    }
                >
                    <List
                        dataSource={values}
                        renderItem={({ createdAt, distance, message, status, surveyFeedback }) => {
                            const isApproved = status.toLowerCase() === 'approved';

                            const statusAvatar = isApproved ? <Avatar icon={<CheckOutlined />} style={{ backgroundColor: '#87d068' }} /> : <Avatar icon={<StopOutlined />} style={{ backgroundColor: '#fe547b' }} />;

                            const feedbackColour = surveyFeedback !== null ? (surveyFeedback === true ? 'green' : 'red') : 'default';
                            const feedback = surveyFeedback !== null ? (surveyFeedback === true ? 'Yes' : 'No') : 'Unknown';
                            const distanceTag = (
                                <>
                                    <Tag>{`${distance} Miles`}</Tag>
                                    <Tag color={feedbackColour}>Successful: {feedback}</Tag>
                                    Created {moment(createdAt).fromNow()}
                                </>
                            );

                            return (
                                <List.Item>
                                    <List.Item.Meta avatar={statusAvatar} description={message} title={distanceTag} />
                                </List.Item>
                            );
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export { AdminSosListDetails };
