import { CheckCircleFilled } from '@ant-design/icons';
const { message, Modal, Space, Switch, Table } = require('antd');
const { useState, useEffect } = require('react');
const { useUpdateUserStatusMutation } = require('./hooks');

const UsersListResults = ({ loading, data }) => {
    const [tabledata, setTableData] = useState(data);
    const [UpdateUserStatus] = useUpdateUserStatusMutation();

    useEffect(() => {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record, index) => {
                const onToggle = async (checked) => {
                    const active_user_msg = 'Are you sure you want to enable this user';
                    const ban_user_msg = 'Are you sure you want to ban this user';
                    const msg = checked ? active_user_msg : ban_user_msg;
                    Modal.confirm({
                        title: 'Confirm',
                        icon: <CheckCircleFilled />,
                        content: (
                            <>
                                <p>{msg}</p>
                                <p>
                                    <b>Name:</b> <em>{record.name}</em>
                                    <br />
                                    <b>Email:</b> <em>{record.email}</em>
                                </p>
                            </>
                        ),
                        async onOk() {
                            const tempTableData = [...tabledata];
                            const tempStatus = checked ? 'Active' : 'Inactive';
                            const foundIndex = tempTableData.findIndex((x) => x.id === record.id);
                            const selectedRow = tempTableData[foundIndex];
                            selectedRow.status = tempStatus;
                            setTableData(tempTableData);
                            await UpdateUserStatus({ variables: { id: Number(selectedRow.id), status: tempStatus } });
                            message.success('Successful');
                        },
                        onCancel() {},
                    });
                };
                return (
                    <Space>
                        <Switch checked={status !== 'Inactive'} onChange={onToggle} title={status !== 'Inactive' ? 'Ban this user' : 'Enable this user'} />
                    </Space>
                );
            },
        },
    ];
    return <Table columns={columns} dataSource={tabledata} loading={loading} rowKey="id" />;
};

export { UsersListResults };
