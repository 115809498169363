import { HeartFilled, PicLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Modal } from 'antd';
import { Link } from 'react-router-dom';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { useAuthenticated, useMobileMediaQuery } from '@/hooks';

const SosMessagesView = ({ children }) => {
    const [isAuthenticated] = useAuthenticated();
    const isMobile = useMobileMediaQuery();

    const shape = isMobile ? 'circle' : 'round';

    return (
        <>
            <PageHeader icon={{ src: MarketplaceIcon }} title="SOS Messages Statistics" />
            <Layout>
                <Card>{children.userResults}</Card>
            </Layout>
        </>
    );
};

export { SosMessagesView };
