import { Redirect, useHistory } from 'react-router-dom';

import { useMeQuery, useQuery } from '@/hooks';

import { useLoginMutation } from './hooks';
import { LoginView } from './LoginView';

let FingerprintJS = require('@fingerprintjs/fingerprintjs-pro');
const _ = require('dotenv').config();

async function get_user_info() {
    return new Promise(async (resolve, reject) => {
        try {
            const fpPromise = FingerprintJS.load({
                token: process.env.REACT_APP_FINGERPRINTJS_TOKEN,
                region: 'eu',
                endpoint: process.env.REACT_APP_END_POINT,
            });

            // Fingerprint
            const fp = await fpPromise;
            var fp_resp = await fp.get({
                extendedResult: true,
            });
            resolve({
                ip: fp_resp.ip,
                visitor_id: fp_resp.visitorId,
                country: fp_resp.ipLocation?.country?.name || '',
                city: fp_resp.ipLocation?.city?.name || '',
                postal_code: fp_resp.ipLocation?.postalCode || '',
                browser_name: fp_resp.browserName,
            });
        } catch (e) {
            reject({
                ip: '',
                visitor_id: '',
                country: '',
                city: '',
                postal_code: '',
                browser_name: '',
            });
        }
    });
}

const LoginContainer = () => {
    const { data, error, loading } = useMeQuery();
    const [login] = useLoginMutation();
    const history = useHistory();
    const query = useQuery();

    const handleLogin = async ({ email, password }, formikBag) => {
        try {
            let userInfo = await get_user_info();
            let fp_obj = {
                email: email,
                password: password,
                visitor_id: userInfo.visitor_id,
                ip: userInfo.ip,
                browser_name: userInfo.browser_name,
                postal_code: userInfo.postal_code,
                city: userInfo.city,
                country: userInfo.country,
            };
            await login({ variables: fp_obj });

            const path = query.get('to');
            history.replace(path);
        } catch (error) {
            if (error.message) {
                formikBag.setErrors({
                    email: '',
                    password: error.message,
                });
            } else {
                formikBag.setErrors({
                    email: '',
                    password: 'Incorrect email or password',
                });
            }
        }
    };

    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;

    return data.me ? <Redirect to="/" /> : <LoginView onLogin={handleLogin} />;
};

export { LoginContainer };
