const viewFactory = (Component, { data, loading, refetch, error }, props) => {
    const handleRetry = () => refetch();

    if (loading) return <Component.Loading {...props} />;
    if (error) return <Component.Error onRetry={handleRetry} {...props} />;
    if (data) return <Component.Data data={data} {...props} />;
    return null;
};

export { viewFactory };
