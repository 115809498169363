import { List } from 'antd';
import { Alert } from 'antd';
import { useHistory } from 'react-router-dom';

import { MarketplaceItem } from '@/components/atoms';

const MarketplaceListResults = ({ loading, marketplaceItems, initialPage, initialPageSize }) => {
    const history = useHistory();

    return (
        <List
            bordered={false}
            dataSource={marketplaceItems}
            itemLayout="vertical"
            loading={loading}
            locale={{
                emptyText: <Alert description="Try using a broader search term, location or different category" message="No marketplace items matched your search" type="info" />,
            }}
            pagination={{
                defaultCurrent: initialPage,
                defaultPageSize: initialPageSize,
                onChange: (page, pageSize) => {
                    history.push(`/marketplace?page=${page}&pageSize=${pageSize}`);
                    window.scrollTo(0, 0);
                },
                pageSizeOptions: ['10', '20', '50'],
            }}
            renderItem={(item) => {
                const image = item.images.length ? item.images[0].url : undefined;
                const { category, ...props } = item;
                return <MarketplaceItem {...props} category={category} image={image} listingDate={item.listingDate} />;
            }}
            size="large"
        />
    );
};

export { MarketplaceListResults };
