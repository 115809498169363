import { Modal, Table } from 'antd';
import moment from 'moment';

import { Sos } from '@/graphql';

type Props = {
    messages: Array<Sos>;
    page: string;
    isModalVisible: boolean;
    handleCancel: () => void;
};

const SosDetailsModal = ({ handleCancel, isModalVisible, messages, page }: Props) => {
    const getFullDate = (date: string) => {
        return moment(date).format('DD-MM-YYYY HH:mm');
    };
    const userTableColumns = [
        {
            title: 'SOS Id',
            dataIndex: page === 'pending' ? 'id' : 'sos_id',
            key: page === 'pending' ? 'id' : 'sos_id',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Distance',
            dataIndex: 'distance',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: string, row: Sos) => {
                if (row.isDeleted) {
                    return 'DELETED';
                } else {
                    return status;
                }
            },
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            minWidth: 120,
            render: (date: string) => getFullDate(date),
        },
    ];

    return (
        <Modal mask={false} okButtonProps={{ style: { display: 'none' } }} onCancel={handleCancel} title="Details" visible={isModalVisible} width={1200}>
            <Table bordered columns={userTableColumns} dataSource={messages} pagination={false} rowKey={page === 'pending' ? 'id' : 'created_at'} />
        </Modal>
    );
};

export { SosDetailsModal };
