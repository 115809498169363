import { Col, Row } from 'antd';
import { node } from 'prop-types';

import styles from './LayoutCentredView.module.css';

const LayoutCentredView = ({ children }) => (
    <Row align="middle" className={styles.row} justify="center">
        <Col>{children}</Col>
    </Row>
);

LayoutCentredView.propTypes = {
    children: node,
};

export { LayoutCentredView };
