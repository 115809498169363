import 'react-alice-carousel/lib/alice-carousel.css';

import { DeleteOutlined, DownOutlined, EnvironmentOutlined, HeartFilled, HeartOutlined, SendOutlined, TagOutlined } from '@ant-design/icons';
import { Button, Card as CardAntDesign, Dropdown, Menu, Space, Tag, Typography } from 'antd';
import { bool, func } from 'prop-types';
import ReactAliceCarousel from 'react-alice-carousel';
import { useModal } from 'react-modal-hook';
import { Link, useLocation } from 'react-router-dom';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { useAuthenticated } from '@/hooks';

import { DeleteModal } from './components';
import styles from './MarketplaceShowView.module.css';

const createCategoryElement = (category) => (category ? <Tag color="blue">{category.name}</Tag> : undefined);
const createFavouriteElement = (isFavourite, onClick) => {
    const Icon = isFavourite ? HeartFilled : HeartOutlined;
    const label = isFavourite ? 'Unfavourite' : 'Favourite';

    return (
        <Button icon={<Icon className={styles.favourite} />} onClick={onClick} size="large" type="link">
            {label}
        </Button>
    );
};
const createLocationElement = (location) =>
    location ? (
        <Typography.Text>
            <EnvironmentOutlined /> {location}
        </Typography.Text>
    ) : undefined;
const createPriceElement = (price) => (price ? `£${price / 100}` : 'Free');

const MarketplaceShowView = ({ isFavourite, onBack, onDelete, onFavourite, onSendMessage, marketplaceItem }) => {
    const location = useLocation();
    const [showDeleteModal, hideDeleteModal] = useModal(() => <DeleteModal onCancel={hideDeleteModal} onOk={handleDelete} />);
    const [isAuthenticated, isAdmin] = useAuthenticated();
    const categoryElement = createCategoryElement(marketplaceItem.category);
    const locationElement = createLocationElement(marketplaceItem.location);
    const priceElement = createPriceElement(marketplaceItem.price);
    const actionsElements = isAuthenticated
        ? [
              createFavouriteElement(isFavourite, onFavourite),
              <Button icon={<SendOutlined />} onClick={onSendMessage} size="large" type="link">
                  Send Message
              </Button>,
          ]
        : null;
    const loginOrSignUpElement = isAuthenticated ? null : (
        <Card bordered={false} style={{ textAlign: 'center' }}>
            Interested? <Link to={{ pathname: '/login', search: `?to=${location.pathname}` }}>Login</Link> or <Link to="/sign-up">Sign up</Link> to send a message about this item
        </Card>
    );

    const handleDelete = (reason, feedback) => {
        hideDeleteModal();
        onDelete(reason, feedback);
    };

    const extra = isAdmin
        ? [
              <Dropdown
                  overlay={
                      <Menu onClick={showDeleteModal}>
                          <Menu.Item danger icon={<DeleteOutlined />} key="1">
                              Delete
                          </Menu.Item>
                      </Menu>
                  }
              >
                  <Button shape="round" size="large">
                      Admin <DownOutlined />
                  </Button>
              </Dropdown>,
          ]
        : null;

    return (
        <>
            <PageHeader extra={extra} icon={{ src: MarketplaceIcon }} onBack={onBack} title={marketplaceItem.title} {...marketplaceItem} />

            <Layout>
                <ReactAliceCarousel
                    autoPlay
                    autoPlayInterval={4000}
                    buttonsDisabled
                    controlsStrategy="responsive"
                    responsive={{
                        0: { items: 1 },
                        992: { items: 2 },
                        1200: { items: 3 },
                    }}
                >
                    {marketplaceItem.images.map((image) => (
                        <img alt={marketplaceItem.title} className={styles.carouselImage} key={marketplaceItem.id} src={image.url} />
                    ))}
                </ReactAliceCarousel>
                <Card
                    actions={actionsElements}
                    extra={
                        <Typography.Title level={4}>
                            <TagOutlined /> {priceElement}
                        </Typography.Title>
                    }
                    title={<Typography.Title level={3}>{marketplaceItem.title}</Typography.Title>}
                >
                    <CardAntDesign.Meta
                        description={marketplaceItem.description}
                        title={
                            <Space>
                                {locationElement}
                                {categoryElement}
                            </Space>
                        }
                    />
                    {loginOrSignUpElement}
                </Card>
            </Layout>
        </>
    );
};

MarketplaceShowView.propTypes = {
    isFavourite: bool,
    onFavourite: func.isRequired,
    onSendMessage: func.isRequired,
};

export { MarketplaceShowView };
