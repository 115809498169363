import { Input, Modal, Radio } from 'antd';
import { func } from 'prop-types';
import { useState } from 'react';

import styles from './DeleteModalView.module.css';

const REASONS = {
    1: 'Uses harmful, abusive and/or inappropriate language',
    2: 'Contains a stock image',
    3: 'Is in breach of our Terms and Conditions',
    4: 'Is in breach of our User Agreement',
    5: 'Is in breach of our Privacy Policy',
    6: 'Is in breach of our Prohibited and Restricted Items Policy',
    7: 'Is in breach of our Prescription Feeds Policy',
    8: 'Other',
};

const DeleteModalView = ({ onCancel, onOk }) => {
    const [reason, setReason] = useState(undefined);
    const [feedback, setFeedback] = useState('');

    const handleCancel = () => {
        setReason(undefined);
        onCancel();
    };

    const handleOk = () => {
        setReason(undefined);
        onOk(REASONS[reason], feedback);
    };

    const handleOptionChange = (event) => setReason(event.target.value);
    const handleFeedbackChange = (event) => setFeedback(event.target.value);

    return (
        <Modal centered closable={false} okButtonProps={{ disabled: reason === undefined }} okText="Yes, Delete" okType="danger" onCancel={handleCancel} onOk={handleOk} title="Are you sure you want to delete this Marketplace Item?" visible>
            <p>Please provide the reason you (the admin) are deleting this item.</p>
            <Radio.Group onChange={handleOptionChange} value={reason}>
                <Radio className={styles.radio} value={1}>
                    {REASONS[1]}
                </Radio>
                <Radio className={styles.radio} value={2}>
                    {REASONS[2]}
                </Radio>
                <Radio className={styles.radio} value={3}>
                    {REASONS[3]}
                </Radio>
                <Radio className={styles.radio} value={4}>
                    {REASONS[4]}
                </Radio>
                <Radio className={styles.radio} value={5}>
                    {REASONS[5]}
                </Radio>
                <Radio className={styles.radio} value={6}>
                    {REASONS[6]}
                </Radio>
                <Radio className={styles.radio} value={7}>
                    {REASONS[7]}
                </Radio>
                <Radio className={styles.radio} value={8}>
                    {REASONS[8]}
                </Radio>
                <Input.TextArea className={styles.feedback} disabled={reason !== 8} onChange={handleFeedbackChange} />
            </Radio.Group>
        </Modal>
    );
};

DeleteModalView.propTypes = {
    onCancel: func.isRequired,
    onOk: func.isRequired,
};

export { DeleteModalView };
