import { Space } from 'antd';

const { useState } = require('react');
const { useQuery } = require('@/hooks');
const { useProductEnquiriesQuery } = require('./hooks');
const { useUserEnquiriesQuery } = require('./hooks');
const { ProductEnquiriesView } = require('./ProductEnquiriesView');
const { ProductEnquiriesResults } = require('./components');
const { UsersEnquiriesResults } = require('./components');

const ProductEnquiriesContainer = () => {
    const query = useQuery();
    // const product_enquiries = useProductEnquiriesQuery();
    const user_enquiries = useUserEnquiriesQuery();
    const page = parseInt(query.get('page'));
    const pageSize = parseInt(query.get('pageSize'));
    // const product_enquiries_data = product_enquiries.data ? JSON.parse(JSON.stringify(product_enquiries.data.productEnquiries)) : [];
    const user_enquiries_data = user_enquiries.data ? JSON.parse(JSON.stringify(user_enquiries.data.userEnquiries)) : [];
    return (
        <ProductEnquiriesView>
            {{
                // productResults: (
                //   <ProductEnquiriesResults
                //     initialPage={page}
                //     initialPageSize={pageSize}
                //     product_enquiries_data={product_enquiries_data}
                //     product_enquiries_loading={product_enquiries.loading}
                //   />
                // ),
                userResults: <UsersEnquiriesResults initialPage={page} initialPageSize={pageSize} user_enquiries_data={user_enquiries_data} user_enquiries_loading={user_enquiries.loading} />,
            }}
        </ProductEnquiriesView>
    );
};

export { ProductEnquiriesContainer };
