import { useHistory } from 'react-router-dom';

import { viewFactory } from '@/utils';

import { useMarketplaceMyFavouritesQuery, useRemoveFavouriteMarketplaceItemMutation } from './hooks';
import { MarketplaceMyFavouritesView } from './MarketplaceMyFavouritesView';

const MarketplaceMyFavouritesContainer = () => {
    const history = useHistory();
    const query = useMarketplaceMyFavouritesQuery();
    const [removeFavouriteMarketplaceItem] = useRemoveFavouriteMarketplaceItemMutation();

    return viewFactory(MarketplaceMyFavouritesView, query, {
        onBack: () => history.push('/marketplace'),
        onRemoveFavourite: (id) =>
            removeFavouriteMarketplaceItem({
                variables: { id },
            }),
    });
};

export { MarketplaceMyFavouritesContainer };
