import { useAuthenticated } from '@/hooks';

import { client } from './../../../graphql';
import { HeaderView } from './HeaderView';
import { useLogoutMutation } from './hooks';

const HeaderContainer = () => {
    const [isAuthenticated, isAdmin, userData] = useAuthenticated();
    const [logout] = useLogoutMutation({
        onCompleted: async () => {
            await client.resetStore();
            window.location = 'https://www.skiggle.co.uk';
        },
    });

    return <HeaderView isAdmin={isAdmin} isAuthenticated={isAuthenticated} onLogout={logout} userData={userData} />;
};

export { HeaderContainer };
