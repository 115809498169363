import { CheckCircleFilled, CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { Link, useHistory, useLocation } from 'react-router-dom';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { donatePopupFields, MarketplaceItem } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { MarketplaceItemStatus, useMarketplaceNewContainerCheckoutMarketplaceItemMutation } from '@/graphql';

import { DeleteModal, MarketplaceItemSection } from './components';

const MarketplaceMyItemsLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ src: MarketplaceIcon }} onBack={onBack} title="My Items" />
        <Layout>{children}</Layout>
    </>
);

const MarketplaceMyItemsData = ({ data, onBack, onDelete, onRenew, onRelist, onPaid }) => {
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
            params[key] = value;
        }
        if (params.type === 'success') {
            SaveSession(params);
        }
        if (params.type === 'cancel') {
            Modal.warning({
                title: 'You payment is pending',
                content: 'You can pay again from my items',
            });
        }
    }, [search]);

    const [dialog, setDialog] = useState({
        id: undefined,
        show: false,
    });
    const [checkoutMarketplaceItem] = useMarketplaceNewContainerCheckoutMarketplaceItemMutation({
        onError: (error) => message.error(error.message),
    });

    const SaveSession = async (params) => {
        try {
            await onPaid({
                id: params.id,
                session: params.session,
                price: Number(params.price),
                session_id: params.session_id,
            });

            Modal.success({
                title: 'Transaction completed successfully',
                content: 'Thank you for your payment',
                onOk: () => {
                    history.replace('/marketplace/my-items');
                    Modal.confirm({
                        cancelText: 'No',
                        centered: true,
                        content: 'Would you like to create another?',
                        icon: <CheckCircleFilled />,
                        okText: 'Yes',
                        onCancel: () => {
                            Modal.confirm({
                                ...donatePopupFields,
                            });
                        },
                        onOk: () => {
                            history.push('/marketplace/new');
                        },
                        title: 'Successfully submitted!',
                        type: 'success',
                    });
                },
            });
        } catch (e) {
            history.replace('/marketplace/my-items');
        }
    };

    const DeleteButton = ({ item }) => (
        <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
                ReactGa.modalview('delete-marketplace-item');
                setDialog({ id: item.id, show: true });
            }}
            type="link"
        >
            Delete
        </Button>
    );

    const PayNowButton = ({ item }) => {
        return (
            <Button
                className="payNowBtn"
                onClick={async () => {
                    const checkoutResponse = await checkoutMarketplaceItem({
                        variables: {
                            id: item.id,
                        },
                    });
                    if (checkoutResponse && checkoutResponse.data && checkoutResponse.data.checkoutMarketplaceItem) {
                        window.open(checkoutResponse.data.checkoutMarketplaceItem);
                    }
                }}
                type="link"
            >
                Pay Now
            </Button>
        );
    };

    if (!data.me) return null;

    return (
        <MarketplaceMyItemsLayout onBack={onBack}>
            <MarketplaceItemSection
                data={data.me.myMarketplaceItems.filter((item) => item.status === MarketplaceItemStatus.New)}
                emptyText="You Have No Items Awaiting Review"
                icon={<ClockCircleOutlined style={{ color: '#7fc1c6' }} />}
                renderItem={(item) => {
                    const image = item.images.length ? item.images[0]?.url : undefined;
                    const { images, isPaymentNeeded, ...props } = item;

                    return <MarketplaceItem {...props} actions={[isPaymentNeeded ? <PayNowButton item={item} /> : null, <DeleteButton item={item} />]} image={image} images={item.images} isNavigable={false} />;
                }}
                title="Awaiting Review"
            />

            <MarketplaceItemSection
                data={data.me.myMarketplaceItems.filter((item) => item.status === MarketplaceItemStatus.ChangeRequired)}
                emptyText="You Have No Items That Require Attention"
                icon={<WarningOutlined style={{ color: '#f97f48' }} />}
                renderItem={(item) => {
                    const image = item.images.length ? item.images[0].url : undefined;
                    const { images, isPaymentNeeded, ...props } = item;

                    return (
                        <MarketplaceItem
                            {...props}
                            actions={[
                                isPaymentNeeded ? <PayNowButton item={item} /> : null,
                                <Link to={`/marketplace/${item.id}/edit`}>
                                    <Button type="link">Edit</Button>
                                </Link>,
                                <DeleteButton item={item} />,
                            ]}
                            image={image}
                            images={item.images}
                            isNavigable={false}
                        />
                    );
                }}
                title="Attention Required"
            />

            <MarketplaceItemSection
                data={data.me.myMarketplaceItems.filter((item) => item.status === MarketplaceItemStatus.Approved)}
                emptyText="You Have Not Posted Any Items"
                icon={<CheckCircleOutlined style={{ color: '#adc965' }} />}
                renderItem={(item) => {
                    const image = item.images.length ? item.images[0].url : undefined;
                    const { images, isPaymentNeeded, ...props } = item;
                    return (
                        <MarketplaceItem
                            {...props}
                            actions={[
                                isPaymentNeeded ? <PayNowButton item={item} /> : null,
                                moment(item.deleteAt).isBefore() ? (
                                    <Tooltip title={'List the product again for 40 days'}>
                                        <Button disabled={!moment(item.deleteAt).isBefore()} icon={moment(item.deleteAt).isBefore() ? <SyncOutlined /> : <ClockCircleOutlined />} onClick={() => onRelist(item.id)} type="link">
                                            Relist (Expired {moment(item.deleteAt).fromNow()})
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={item.isRenewable ? 'Renew for 40 days' : 'Renew only possible within 4 days of expiry'}>
                                        <Button disabled={!item.isRenewable} icon={item.isRenewable ? <SyncOutlined /> : <ClockCircleOutlined />} onClick={() => onRenew(item.id)} type="link">
                                            Renew (Expires {moment(item.deleteAt).fromNow()})
                                        </Button>
                                    </Tooltip>
                                ),
                                <DeleteButton item={item} />,
                            ]}
                            image={image}
                            images={item.images}
                        />
                    );
                }}
                title="Live Adverts"
            />

            <DeleteModal
                onCancel={() => setDialog({ id: undefined, show: false })}
                onOk={async () => {
                    await onDelete({ id: dialog.id });
                    setDialog({ id: undefined, show: false });
                }}
                visible={dialog.show}
            />
        </MarketplaceMyItemsLayout>
    );
};

MarketplaceMyItemsData.propTypes = {
    data: shape({
        me: shape({
            id: string.isRequired,
            myMarketplaceItems: arrayOf(
                shape({
                    deleteAt: string.isRequired,
                    id: string.isRequired,
                    images: arrayOf(
                        shape({
                            url: string.isRequired,
                        })
                    ),
                    isRenewable: bool.isRequired,
                })
            ),
        }),
    }),
    onBack: func.isRequired,
    onDelete: func.isRequired,
    onRenew: func.isRequired,
};

const MarketplaceMyItemsView = {
    Error: ({ onBack, ...props }) => (
        <MarketplaceMyItemsLayout onBack={onBack}>
            <PageError {...props} />
        </MarketplaceMyItemsLayout>
    ),
    Data: MarketplaceMyItemsData,
    Loading: ({ onBack }) => (
        <MarketplaceMyItemsLayout onBack={onBack}>
            <PageLoading />
        </MarketplaceMyItemsLayout>
    ),
};

export { MarketplaceMyItemsView };
