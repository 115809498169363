import { Col, Row, Typography } from 'antd';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import logo from '@/assets/logo.svg';
import { Card } from '@/components/atoms';

import styles from './PageCentredFullScreenView.module.css';

const PageCentredFullScreenView = ({ description, children, title, showTitle = true }) => {
    const gutterProps = { lg: 7, md: 6, sm: 4, xl: 8, xs: 0, xxl: 9 };
    const contentProps = { lg: 10, md: 12, sm: 16, xl: 8, xs: 24, xxl: 6 };

    const titleElement = title && showTitle ? <Typography.Title level={3}>{title}</Typography.Title> : null;
    const descriptionElement = description ? <Typography.Text type="secondary">{description}</Typography.Text> : null;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Row align="middle" className={styles.row} justify="center">
                <Col {...gutterProps} />
                <Col {...contentProps}>
                    <Card
                        headStyle={{ borderBottom: 'none' }}
                        title={
                            <div className={styles.title}>
                                <img
                                    alt="Skiggle logo"
                                    className={classnames({
                                        [styles['logo']]: (showTitle && title) || description,
                                    })}
                                    height="42px"
                                    src={logo}
                                />
                                {titleElement}
                                {descriptionElement}
                            </div>
                        }
                    >
                        {children}
                    </Card>
                </Col>
                <Col {...gutterProps} />
            </Row>
        </>
    );
};

export { PageCentredFullScreenView };
