const ZOOMS = {
    1: 13,
    2: 12,
    5: 11,
    10: 9.8,
    15: 9.2,
    20: 8.8,
    25: 8.5,
    30: 8.4,
    35: 8.2,
    40: 8,
    45: 8,
    50: 7.8,
};

// @ts-ignore
const getZoom = (distance: number) => (distance !== 0 ? ZOOMS[distance] : 5);

export { getZoom };
