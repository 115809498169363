import { Card, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { func } from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';

import styles from './LoginView.module.css';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Please enter a email'),
    password: Yup.string().required('Please enter a password'),
});

const LoginView = ({ onLogin }) => (
    <PageCentredFullScreen showTitle={false} title="Login">
        <Formik initialValues={{}} onSubmit={onLogin} validationSchema={validationSchema}>
            <Form size="large">
                <Form.Item name="email">
                    <Input autoFocus name="email" placeholder="Email" />
                </Form.Item>

                <Form.Item name="password">
                    <Input.Password name="password" placeholder="Password" />
                </Form.Item>

                <Form.Item name>
                    <SubmitButton block>Log in</SubmitButton>
                </Form.Item>

                <div className={styles.forgotPassword}>
                    <Link to="/forgot-password">Forgot password?</Link>
                </div>
            </Form>
        </Formik>

        <Divider plain />

        <Card bordered={false} className={styles.signupCard}>
            Don't have an account? <Link to="/sign-up">Sign up</Link>
        </Card>
    </PageCentredFullScreen>
);

LoginView.propTypes = {
    onLogin: func.isRequired,
};

export { LoginView };
