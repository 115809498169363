import { Alert, Col, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from '@/components/atoms';
import { useMarketplaceNewContainerCheckoutMarketplaceItemMutation } from '@/graphql';

const PaymentLinkView = () => {
    const { id } = useParams();
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    const [checkoutMarketplaceItem] = useMarketplaceNewContainerCheckoutMarketplaceItemMutation({});

    useEffect(() => {
        if (id) {
            loadCheckout(id);
        }
    }, [id]);

    const loadCheckout = async (id) => {
        checkoutMarketplaceItem({
            variables: {
                id,
            },
        })
            .then((checkoutResponse) => {
                if (checkoutResponse && checkoutResponse.data && checkoutResponse.data.checkoutMarketplaceItem) {
                    window.open(checkoutResponse.data.checkoutMarketplaceItem, '_self');
                }
            })
            .catch(() => {
                setIsLinkExpired(true);
            });
    };

    return (
        <>
            {isLinkExpired ? (
                <Row align="top" justify="center" style={{ minHeight: '70vh', marginTop: '40px' }}>
                    <Card style={{ minWidth: '200px' }}>
                        <Alert
                            description={
                                <>
                                    <p>The payment link has expired or already been used</p>
                                    <p>
                                        <strong>Please visit your product listing page, to see your product listings</strong>
                                    </p>
                                    <a href="/marketplace/my-items">View my items</a>
                                </>
                            }
                            message="Sorry, your payment link has expired"
                            showIcon
                            type="info"
                        />
                    </Card>
                </Row>
            ) : (
                <>
                    <Row align="middle" justify="center" style={{ minHeight: '70vh' }}>
                        <Col>
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                            <br />
                            <Typography.Title level={4}>Verifying your payment URL...</Typography.Title>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export { PaymentLinkView };
