const BuyingSellingAdvice = {
    TITLE: 'Buying and Selling Advice',
    MESSAGE: (
        <>
            Please note that 'Marketplace' sales are a contract between the two parties involved and Skiggle does not take <strong>any</strong> responsibility for the transaction. It is the seller’s responsibility to ensure that the item is described accurately and to agree any postage and packing costs. It is the responsibility of both parties to agree payment terms. Since the sales are private,
            normal consumer rights may not apply.
        </>
    ),
};

export { BuyingSellingAdvice };
