import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, List, Modal } from 'antd';
import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';

import SosIcon from '@/assets/icons/sos.png';
import { Card, SosDetailsModal, SosItem } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { Sos } from '@/graphql';

import { RejectModal } from './components';

type Props = {
    data?: Pick<Sos, 'createdAt' | 'distance' | 'id' | 'message' | 'status' | 'user'>[];
    loading: boolean;
    onApprove: (id: string) => Promise<void>;
    onReject: (id: string, reason: string, feedback: string) => Promise<void>;
};

const SosPendingView = ({ data, loading, onApprove, onReject }: Props) => {
    const [selectedId, setSelectedId] = useState('');
    const [showRejectModal, hideRejectModal] = useModal(() => <RejectModal id={selectedId} onCancel={hideRejectModal} onOk={handleReject} />, [selectedId]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mySos, setMySos] = useState<Array<Sos>>([]);
    const handleReject = (id: string, reason: string, feedback: string) => {
        hideRejectModal();
        onReject(id, reason, feedback);
    };

    const handleRejectClick = (id: string) => {
        setSelectedId(id);
        showRejectModal();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <PageHeader icon={{ src: SosIcon }} title="Pending SOS Requests" />
            <Layout>
                <Card>
                    <List
                        dataSource={data}
                        itemLayout="vertical"
                        loading={loading}
                        locale={{
                            emptyText: 'There are no pending SOS requests',
                        }}
                        renderItem={(sos) => {
                            return (
                                <SosItem
                                    {...sos}
                                    actions={[
                                        <Button
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: 'Confirm',
                                                    icon: <ExclamationCircleOutlined />,
                                                    content: (
                                                        <>
                                                            Are you sure you want to approve the following the SOS request?
                                                            <div>
                                                                "<i>{sos.message}</i>"
                                                            </div>
                                                        </>
                                                    ),
                                                    okText: 'Proceed',
                                                    cancelButtonProps: {
                                                        danger: true,
                                                    },
                                                    onOk() {
                                                        onApprove(sos.id);
                                                    },
                                                    onCancel() {},
                                                });
                                            }}
                                            type="primary"
                                        >
                                            Approve
                                        </Button>,
                                        <Button danger onClick={() => handleRejectClick(sos.id)}>
                                            Reject
                                        </Button>,
                                        sos && sos.user && sos.user.mySos ? (
                                            <Button
                                                onClick={() => {
                                                    setMySos(sos.user.mySos);
                                                    showModal();
                                                }}
                                                type="link"
                                            >
                                                SOS History ({sos.user.mySos.length})
                                            </Button>
                                        ) : null,
                                    ]}
                                />
                            );
                        }}
                        size="large"
                    />
                </Card>
                <SosDetailsModal handleCancel={handleCancel} isModalVisible={isModalVisible} messages={mySos} page="pending" />
            </Layout>
        </>
    );
};

export { SosPendingView };
