import { PlusOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { RcFile } from 'antd/lib/upload/interface';
import { FieldProps } from 'formik';
import { Field } from 'formik-antd';
import { FormikFieldProps } from 'formik-antd/lib/FieldProps';
import { memoize } from 'lodash';
import { forwardRef, Ref } from 'react';

type Props = FormikFieldProps & UploadProps;

type UploadRcFile = RcFile & {
    id: string;
};

const memoizedFileList = memoize((fileList) => fileList?.map((file: UploadRcFile) => ({ ...file, uid: file.uid ? file.uid : file.id })));

const ImageUpload = forwardRef(({ name, validate, fast, onChange: $onChange, ...props }: Props, ref: Ref<typeof Upload>) => (
    <Field fast={fast} name={name} validate={validate}>
        {({ field: { value, onChange, onBlur } }: FieldProps) => {
            const fileList = memoizedFileList(value);
            return (
                <Upload
                    accept=".jpg"
                    beforeUpload={() => false}
                    fileList={fileList}
                    listType="picture-card"
                    multiple
                    name={name}
                    onChange={(info) => {
                        onChange({ target: { value: info.fileList, name } });
                        $onChange && $onChange(info);
                        onBlur({ target: { name } });
                    }}
                    ref={ref}
                    showUploadList={{ showPreviewIcon: false }}
                    {...props}
                >
                    <Button icon={<PlusOutlined />} size="large" type="link" />
                </Upload>
            );
        }}
    </Field>
));

export { ImageUpload };
