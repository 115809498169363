import { Form } from 'antd';

import { UsersListSearchBarView } from './UsersListSearchBarView';

const UsersSearchBarContainer = ({ numberOfMatches, onSearch }) => {
    const [form] = Form.useForm();

    const handleSearchReset = () => {
        form.resetFields();
        onSearch({});
    };

    return <UsersListSearchBarView form={form} numberOfMatches={numberOfMatches} onSearch={onSearch} onSearchReset={handleSearchReset} />;
};

export { UsersSearchBarContainer };
