import { CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import SosIcon from '@/assets/icons/sos.png';
import { SosItem } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { SosStatus } from '@/graphql';

import { SosSection } from './components';

const SosMySosLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ src: SosIcon }} onBack={onBack} title="My SOS" />
        <Layout>{children}</Layout>
    </>
);

const SosMySosData = ({ data, onBack, onDelete }) => {
    const DeleteButton = ({ sos }) => (
        <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
                Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <>
                            Do you want to delete this SOS request?
                            <div>
                                "<i>{sos.message}</i>"
                            </div>
                        </>
                    ),
                    cancelButtonProps: {
                        danger: true,
                    },
                    onOk() {
                        onDelete(sos.id);
                    },
                    onCancel() {},
                });
            }}
            type="link"
        >
            Delete
        </Button>
    );

    return (
        <SosMySosLayout onBack={onBack}>
            <SosSection data={data.me.mySos.filter((sos) => sos.status === SosStatus.Pending)} emptyText="You Have No SOS Awaiting Review" icon={<ClockCircleOutlined style={{ color: '#7fc1c6' }} />} renderItem={(sos) => <SosItem {...sos} actions={[<DeleteButton sos={sos} />]} />} title="Awaiting Review" />

            <SosSection
                data={data.me.mySos.filter((sos) => sos.status === SosStatus.Rejected)}
                emptyText="You Have No SOS That Require Attention"
                icon={<WarningOutlined style={{ color: '#f97f48' }} />}
                renderItem={(sos) => (
                    <SosItem
                        {...sos}
                        actions={[
                            <Link to={`/sos/${sos.id}/edit`}>
                                <Button type="link">Edit</Button>
                            </Link>,
                            <DeleteButton sos={sos} />,
                        ]}
                    />
                )}
                title="Attention Required"
            />

            <SosSection data={data.me.mySos.filter((sos) => sos.status === SosStatus.Approved)} emptyText="You Have Not Sent Any SOS" icon={<CheckCircleOutlined style={{ color: '#adc965' }} />} renderItem={(sos) => <SosItem {...sos} />} title="Sent SOS" />
        </SosMySosLayout>
    );
};

SosMySosData.propTypes = {
    data: shape({
        me: shape({
            id: string.isRequired,
            sos: arrayOf(
                shape({
                    id: string.isRequired,
                })
            ),
        }),
    }),
    onBack: func.isRequired,
    onDelete: func.isRequired,
};

const SosMySosView = {
    Error: ({ onBack, ...props }) => (
        <SosMySosLayout onBack={onBack}>
            <PageError {...props} />
        </SosMySosLayout>
    ),
    Data: SosMySosData,
    Loading: ({ onBack }) => (
        <SosMySosLayout onBack={onBack}>
            <PageLoading />
        </SosMySosLayout>
    ),
};

export { SosMySosView };
