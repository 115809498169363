import { Alert, Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';

const validationSchema = Yup.object().shape({
    code: Yup.string().length(6, 'Code must be 6 characters').required('Please enter a code'),
});

const VerifyMobileView = ({ success, onSubmit }) => {
    const content = success ? (
        <Alert description="Your mobile has been verified. You can safely close this window." message="Success!" showIcon type="success" />
    ) : (
        <Formik initialValues={{}} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form colon={false} size="large">
                <Form.Item name="code">
                    <Input autoFocus name="code" placeholder="Code" />
                </Form.Item>

                <Form.Item name>
                    <SubmitButton block>Verify</SubmitButton>
                </Form.Item>
            </Form>
        </Formik>
    );

    return (
        <PageCentredFullScreen title="Mobile verification">
            {content}
            <Link to="/profile">
                <Button block type="link">
                    Back to Profile
                </Button>
            </Link>
        </PageCentredFullScreen>
    );
};

VerifyMobileView.propTypes = {
    onSubmit: func.isRequired,
    success: bool,
};

export { VerifyMobileView };
