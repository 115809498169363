import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Grid, Input, Select, Typography } from 'antd';

import { orderedCategories } from '@/utils';

const { useBreakpoint } = Grid;

const MarketplaceListSearchBarView = ({ categories, form, loading, numberOfMatches, onSearch, onSearchReset }) => {
    const screens = useBreakpoint();
    const verticalForm = screens.lg === false;

    return (
        <Form colon={false} form={form} layout={verticalForm ? 'vertical' : 'inline'} onFinish={onSearch} size="large">
            <Form.Item label={<SearchOutlined />} name="title" rules={[{ max: 255 }]}>
                <Input placeholder="Search e.g. Feed" />
            </Form.Item>
            <Form.Item name="location" rules={[{ max: 255 }]}>
                <Input placeholder="Location e.g. Preston" />
            </Form.Item>
            <Form.Item name="categoryId">
                <Select loading={loading} placeholder="Category" style={{ width: verticalForm ? undefined : 215 }}>
                    {orderedCategories(categories).map(({ id, name }) => (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button block={verticalForm} htmlType="submit" type="primary">
                    Search
                </Button>
            </Form.Item>
            <Form.Item>
                <Button block={verticalForm} onClick={onSearchReset}>
                    Reset
                </Button>
            </Form.Item>
            <Form.Item>
                <Typography.Text strong>{numberOfMatches} Matches</Typography.Text>
            </Form.Item>
        </Form>
    );
};

export { MarketplaceListSearchBarView };
