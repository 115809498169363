import { gql, useQuery } from '@apollo/client';

const useMeQuery = () =>
    useQuery(
        gql`
            query MeQuery {
                me {
                    email
                    emailVerified
                    favouriteMarketplaceItems {
                        id
                    }
                    id
                    image {
                        id
                        url
                    }
                    location
                    locked
                    lockedReason
                    mobile
                    mobileVerified
                    myMarketplaceItems {
                        id
                    }
                    name
                    roles {
                        id
                        type
                    }
                    isBanned
                    sosReceive
                    sosReceiveEmail
                    sosReceiveMobile
                }
            }
        `,
        { fetchPolicy: 'network-only' }
    );

export { useMeQuery };
