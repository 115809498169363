import { CalendarOutlined } from '@ant-design/icons';
import { Col, Row, Space, Statistic } from 'antd';
import { times } from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { Card } from '@/components/atoms';

const TIME_RANGE_IN_MONTHS = 12;

const AdminSosList12Months = ({ total, values }) => {
    const labels = [];
    times(TIME_RANGE_IN_MONTHS, (index) => {
        labels.unshift(index === 0 ? 'This Month' : moment().subtract(index, 'month').format('MMM'));
    });

    const chartData = {
        labels: labels,
        datasets: [
            {
                backgroundColor: 'rgba(249, 127, 73, 0.5)',
                borderColor: 'rgb(249, 127, 73)',
                borderWidth: 1,
                data: values,
                hoverBackgroundColor: 'rgb(249, 127, 73)',
                label: 'Users',
            },
        ],
    };
    const options = {
        legend: {
            display: false,
        },
    };

    return (
        <Row gutter={[0, 32]}>
            <Col span={24}>
                <Card
                    extra={<Statistic title="Total" value={total} />}
                    title={
                        <Space>
                            <CalendarOutlined /> Last 12 Months
                        </Space>
                    }
                >
                    <Bar data={chartData} height={75} options={options} />
                </Card>
            </Col>
        </Row>
    );
};

export { AdminSosList12Months };
