import { useMeQuery } from '@/hooks';

import { LockedView } from './LockedView';

const LockedContainer = () => {
    const { data, error, loading } = useMeQuery();

    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;

    return <LockedView reason={data.me.lockedReason} />;
};

export { LockedContainer };
