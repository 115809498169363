import { Col, List, Row, Space } from 'antd';
import { ReactNode } from 'react';

import { Card } from '@/components/atoms';
import { MarketplaceItem } from '@/graphql';

type Props = {
    actions: ReactNode[];
    data: MarketplaceItem[];
    emptyText: string;
    icon: ReactNode;
    renderItem: (item: MarketplaceItem) => ReactNode;
    title: string;
};

const MarketplaceItemSection = ({ data, emptyText, icon, renderItem, title }: Props) => (
    <Row gutter={[0, 32]}>
        <Col span={24}>
            <Card
                title={
                    <Space>
                        {icon} {title}
                    </Space>
                }
            >
                <List bordered={false} dataSource={data} itemLayout="vertical" locale={{ emptyText }} renderItem={renderItem} size="large" />
            </Card>
        </Col>
    </Row>
);

export { MarketplaceItemSection };
