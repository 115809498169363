import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Result, Typography } from 'antd';
import classnames from 'classnames';
import { useState } from 'react';

import styles from './MarketplaceNewNotice.module.css';

type Props = {
    onNext: () => void;
};

const MarketplaceNewNotice = ({ onNext }: Props) => {
    const [understood, setUnderstood] = useState(false);

    const handleUnderstoodChanged = () => setUnderstood(!understood);

    return (
        <Result
            extra={[
                <div className={styles.agreed} key="1">
                    <Checkbox checked={understood} onChange={handleUnderstoodChanged}>
                        I have read and agreed to the conditions.
                    </Checkbox>
                </div>,
                <Button disabled={!understood} key="2" onClick={onNext} size="large" type="primary">
                    Next
                </Button>,
            ]}
            subTitle={
                <>
                    Skiggle accepts no responsibility for the movement of, or condition of any care-based items through the website (see{' '}
                    <a href="https://www.skiggle.co.uk/terms-of-use/terms-and-conditions" rel="noopener noreferrer" target="_blank">
                        terms and conditions
                    </a>
                    ), however would like all users to take note of the following guidelines:
                    <div
                        className={classnames({
                            [styles['notice']]: true,
                            'ant-result-content': true,
                        })}
                    >
                        <ol>
                            <li>
                                <Typography.Paragraph>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 16,
                                        }}
                                    >
                                        That there should be NO MOVEMENT of:
                                    </Typography.Text>
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    <CloseCircleOutlined className={styles.redIcon} /> Medication (prescription and non-prescription), sharps, needles or oxygen cylinders (full or empty)
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    <CloseCircleOutlined className={styles.redIcon} /> NHS equipment (all NHS equipment should be returned to the local NHS loan store)
                                </Typography.Paragraph>
                                <Typography.Paragraph strong>All items should be clean, sterile, in date and in working order.</Typography.Paragraph>
                            </li>
                            <li>
                                <Typography.Paragraph>Due to increased costs in running the charity, unfortunately we can no longer offer the service for free. We will be asking for a compulsory donation based on the following price bands that you wish to sell your products for.</Typography.Paragraph>
                                <Typography.Paragraph>{'Free Items -> Free to list.'}</Typography.Paragraph>
                                <Typography.Paragraph>{'£0.01 - £6.00 -> listing fee of 60p.'}</Typography.Paragraph>
                                <Typography.Paragraph>{'£6.01 and above -> 10% listing fee.'}</Typography.Paragraph>
                                <Typography.Paragraph strong>If you choose to pay later, your item will not be reviewed by our admin team until you are able to complete your payment. This means that your item will not be uploaded to our marketplace. Please check your emails for guidance on what to do next.</Typography.Paragraph>
                            </li>
                        </ol>
                    </div>
                </>
            }
            title="Notice"
        />
    );
};

export { MarketplaceNewNotice };
