const { useState } = require('react');
const { useQuery } = require('@/hooks');
const { useGetUsersListQuery } = require('./hooks');
const { UsersListView } = require('./UsersListView');
const { UsersListResults } = require('./components');
const { UsersListSearchBar } = require('./components');

const UsersListContainer = () => {
    const query = useQuery();
    const [search, setSearch] = useState({});
    const { data, loading } = useGetUsersListQuery({ ...search });

    const page = parseInt(query.get('page'));
    const pageSize = parseInt(query.get('pageSize'));
    const users = data ? JSON.parse(JSON.stringify(data.getUsersList)) : [];

    return (
        <UsersListView>
            {{
                results: <UsersListResults data={users} initialPage={page} initialPageSize={pageSize} loading={loading} />,
                searchBar: <UsersListSearchBar numberOfMatches={users.length} onSearch={setSearch} />,
            }}
        </UsersListView>
    );
};

export { UsersListContainer };
