import { useHistory } from 'react-router-dom';

import { useAdminSosListContainerQuery } from '@/graphql';
import { viewFactory } from '@/utils';

import { AdminSosListView } from './AdminSosListView';

const AdminSosListContainer = () => {
    const history = useHistory();
    const query = useAdminSosListContainerQuery();

    //@ts-ignore
    return viewFactory(AdminSosListView, query, {
        onBack: () => history.push('/'),
    });
};

export { AdminSosListContainer };
