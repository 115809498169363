import { CalendarOutlined } from '@ant-design/icons';
import { Col, Row, Space, Statistic } from 'antd';
import { times } from 'lodash';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import { Card } from '@/components/atoms';

const TIME_RANGE_IN_DAYS = 30;

const AdminSosList30Days = ({ total, values }) => {
    const labels = [];
    times(TIME_RANGE_IN_DAYS, (index) => {
        labels.unshift(index === 0 ? 'Today' : moment().subtract(index, 'day').format('Do'));
    });

    const chartData = {
        labels: labels,
        datasets: [
            {
                backgroundColor: 'rgba(37, 136, 201, 0.5)',
                borderColor: 'rgb(37, 136, 201)',
                borderWidth: 1,
                data: values,
                hoverBackgroundColor: 'rgb(37, 136, 201)',
                label: 'Users',
            },
        ],
    };
    const options = {
        legend: {
            display: false,
        },
    };

    return (
        <Row gutter={[0, 32]}>
            <Col span={24}>
                <Card
                    extra={<Statistic title="Total" value={total} />}
                    title={
                        <Space>
                            <CalendarOutlined /> Last 30 Days
                        </Space>
                    }
                >
                    <Bar data={chartData} height={75} options={options} />
                </Card>
            </Col>
        </Row>
    );
};

export { AdminSosList30Days };
