import { Form } from 'antd';

import { useMarketplaceListSearchBarQuery } from './hooks';
import { MarketplaceListSearchBarView } from './MarketplaceListSearchBarView';

const MarketplaceListSearchBarContainer = ({ numberOfMatches, onSearch }) => {
    const [form] = Form.useForm();
    const { data, loading } = useMarketplaceListSearchBarQuery();

    const handleSearchReset = () => {
        form.resetFields();
        onSearch({});
    };

    const categories = data ? data.marketplaceCategories : [];

    return <MarketplaceListSearchBarView categories={categories} form={form} loading={loading} numberOfMatches={numberOfMatches} onSearch={onSearch} onSearchReset={handleSearchReset} />;
};

export { MarketplaceListSearchBarContainer };
