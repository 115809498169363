import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { withErrorsFeedback } from '@/forms';
import { useMeQuery } from '@/hooks';

import { client } from './../../../graphql';
import { useDeleteMeMutation, useResendVerifyEmailMutation, useResendVerifyMobileMutation, useUpdateMeMutation } from './hooks';
import { ProfileView } from './ProfileView';

const getImageObjectOrFileUpload = (image) => {
    if (image) {
        if (image.id) {
            return { id: image.id };
        } else if (image.file) {
            return image.file;
        }
    }

    return null;
};

const ProfileContainer = () => {
    const { data, error, loading } = useMeQuery();
    const [deleteMe] = useDeleteMeMutation({
        onCompleted: async () => {
            await client.resetStore();
            window.location = 'https://www.skiggle.co.uk';
        },
    });
    const [resendVerifyEmail] = useResendVerifyEmailMutation();
    const [resendVerifyMobile] = useResendVerifyMobileMutation();
    const [updateMe] = useUpdateMeMutation();

    const handleResendEmailVerification = () => resendVerifyEmail();
    const handleResendMobileVerification = () => resendVerifyMobile();
    const handleSubmit = withErrorsFeedback(async ({ email, image, location, mobile, name, password, sosReceive, sosReceiveEmail, sosReceiveMobile }) => {
        const details = await geocodeByAddress(location);
        const { lat, lng } = await getLatLng(details[0]);

        await updateMe({
            variables: {
                input: {
                    email,
                    image: getImageObjectOrFileUpload(image),
                    latitude: lat,
                    location,
                    longitude: lng,
                    mobile,
                    name,
                    password,
                    sosReceive,
                    sosReceiveEmail,
                    sosReceiveMobile,
                },
            },
        });
    });

    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;

    return <ProfileView initialValues={data.me} onDeleteAccount={deleteMe} onResendEmailVerification={handleResendEmailVerification} onResendMobileVerification={handleResendMobileVerification} onSubmit={handleSubmit} />;
};

export { ProfileContainer };
