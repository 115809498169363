import { Modal, Radio } from 'antd';
import { func } from 'prop-types';
import { useState } from 'react';
import ReactGa from 'react-ga';

import styles from './DeleteModalView.module.css';

const REASONS = {
    1: 'No Longer Require the Service',
    2: 'Receiving Too Much Marketing Material',
    6: 'Other',
};

const DeleteModalView = ({ onClose, onSubmit }) => {
    const [reason, setReason] = useState(undefined);

    const handleCancel = () => {
        setReason(undefined);
        onClose();
    };

    const handleOk = () => {
        setReason(undefined);
        ReactGa.event({
            action: 'Deleted',
            category: 'User Account',
            label: REASONS[reason],
        });
        onSubmit();
    };

    const handleOptionChange = (event) => setReason(event.target.value);

    return (
        <Modal centered closable={false} okButtonProps={{ danger: true, disabled: reason === undefined, type: 'primary' }} okText="Yes, Delete Account" okType="danger" onCancel={handleCancel} onOk={handleOk} title="Delete Account?" visible>
            <p>Please provide the reason you are deleting your account</p>
            <Radio.Group onChange={handleOptionChange} value={reason}>
                <Radio className={styles.radio} value={1}>
                    I/we no longer require the service
                </Radio>
                <Radio className={styles.radio} value={2}>
                    I/we am/are receiving too much marketing material and/or emails
                </Radio>
                <Radio className={styles.radio} value={3}>
                    Other
                </Radio>
            </Radio.Group>
        </Modal>
    );
};

DeleteModalView.propTypes = {
    onClose: func.isRequired,
    onSubmit: func.isRequired,
};

export { DeleteModalView };
