import { useHistory } from 'react-router-dom';

import { viewFactory } from '@/utils';

import { AdminUsersListView } from './AdminUsersListView';
import { useAdminUsersListQuery } from './hooks';

const AdminUsersListContainer = () => {
    const history = useHistory();
    const query = useAdminUsersListQuery();

    return viewFactory(AdminUsersListView, query, {
        onBack: () => history.push('/'),
    });
};

export { AdminUsersListContainer };
