import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useUsersByIdEnquiriesQuery = (ids) =>
    useQuery(
        gql`
            query UserByIdEnquiriesQuery($ids: [Int!]) {
                getAllByUserIdEnquiries(ids: $ids) {
                    user_id
                    advert_id
                    title
                    description
                    message
                    created_at
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
            variables: { ids: ids },
        }
    );

export { useUsersByIdEnquiriesQuery };
