import { CheckCircleTwoTone, CloseCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import { bool, func, shape, string } from 'prop-types';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { LayoutNarrow } from '@/components/templates';
import { LocationInput } from '@/forms/components';
import { email, location, mobile, name, password, passwordConfirm } from '@/forms/validationSchema';

import { AvatarFormItem, DeleteModal } from './components';
import styles from './ProfileView.module.css';

const validationSchema = Yup.object().shape({
    email,
    location,
    mobile,
    name,
    password,
    passwordConfirm,
});

const createAddon = (verified) =>
    verified ? (
        <>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Verified
        </>
    ) : (
        <>
            <CloseCircleTwoTone twoToneColor="#eb2f96" /> Not Verified
        </>
    );

const createEmailExtra = (verified, onResendClick) =>
    verified ? null : (
        <Button onClick={onResendClick} size="small" type="link">
            Resend verification
        </Button>
    );

const createMobileExtra = (verified, onResendClick) =>
    verified ? null : (
        <>
            <Button onClick={onResendClick} size="small" type="link">
                Resend verification
            </Button>
            <Link to="/verify-mobile">
                <Button size="small" type="link">
                    Enter code
                </Button>
            </Link>
        </>
    );

const ProfileView = ({ initialValues, onDeleteAccount, onResendEmailVerification, onResendMobileVerification, onSubmit }) => {
    const [showDeleteModal, hideDeleteModal] = useModal(() => <DeleteModal onClose={hideDeleteModal} onSubmit={onDeleteAccount} />);

    return (
        <>
            <PageHeader icon={{ icon: <UserOutlined />, style: { color: 'black', backgroundColor: 'white' } }} title="Profile" />
            <LayoutNarrow>
                <Card>
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({ values }) => (
                            <Form colon={false} labelCol={{ span: 3 }} size="large" wrapperCol={{ span: 20 }}>
                                <Form.Item name="image" wrapperCol={{ span: 24 }}>
                                    <AvatarFormItem name="image" />
                                </Form.Item>

                                <Form.Item name="name" wrapperCol={{ span: 24 }}>
                                    <Input bordered={false} className={styles.name} name="name" placeholder="Name" />
                                </Form.Item>

                                <Form.Item extra={createEmailExtra(values.emailVerified, onResendEmailVerification)} label="Email" name="email">
                                    <Input addonAfter={createAddon(values.emailVerified)} name="email" />
                                </Form.Item>

                                <Form.Item extra={createMobileExtra(values.mobileVerified, onResendMobileVerification)} label="Mobile" name="mobile">
                                    <Input addonAfter={createAddon(values.mobileVerified)} name="mobile" />
                                </Form.Item>

                                <Form.Item label="Location" name="location">
                                    <LocationInput name="location" />
                                </Form.Item>

                                <Card bordered={false} title="Change password">
                                    <Form.Item label="Password" labelCol={{ span: 7 }} name="password">
                                        <Input.Password name="password" />
                                    </Form.Item>

                                    <Form.Item label="Confirm password" labelCol={{ span: 7 }} name="passwordConfirm">
                                        <Input.Password name="passwordConfirm" />
                                    </Form.Item>
                                </Card>

                                <Card
                                    bordered={false}
                                    extra={
                                        <Form.Item name="sosReceive" noStyle>
                                            <Switch name="sosReceive" />
                                        </Form.Item>
                                    }
                                    title="Receive SOS requests"
                                >
                                    <Form.Item label="via Email" labelCol={{ span: 4 }} name="sosReceiveEmail">
                                        <Switch disabled={!values.sosReceive} name="sosReceiveEmail" />
                                    </Form.Item>

                                    <Form.Item label="via Mobile" labelCol={{ span: 4 }} name="sosReceiveMobile">
                                        <Switch disabled={!values.sosReceive} name="sosReceiveMobile" />
                                    </Form.Item>
                                </Card>

                                <Form.Item name wrapperCol={{ offset: 3 }}>
                                    <SubmitButton>Update</SubmitButton>
                                </Form.Item>

                                <Card bordered={false} title="Delete account">
                                    <Button block danger onClick={showDeleteModal}>
                                        Delete your account
                                    </Button>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </LayoutNarrow>
        </>
    );
};

ProfileView.propTypes = {
    initialValues: shape({
        email: string.isRequired,
        emailVerified: bool.isRequired,
        name: string.isRequired,
    }).isRequired,
    onDeleteAccount: func.isRequired,
    onResendEmailVerification: func.isRequired,
    onResendMobileVerification: func.isRequired,
    onSubmit: func.isRequired,
};

export { ProfileView };
