import { Input, Modal, Radio } from 'antd';
import { useState } from 'react';

import styles from './RejectModalView.module.css';

type Props = {
    id: string;
    onCancel: () => void;
    onOk: (id: string, reason: string, feedback: string) => void;
};

const REASONS = {
    1: 'It has been deemed rude and/or inappropriate',
    2: 'Skiggle is unable to provide this service',
    3: 'Is it in breach of our terms and conditions',
    4: 'Other',
};

const RejectModalView = ({ id, onCancel, onOk }: Props) => {
    const [reason, setReason] = useState(undefined);
    const [feedback, setFeedback] = useState('');

    const handleCancel = () => {
        setReason(undefined);
        onCancel();
    };

    const handleOk = () => {
        setReason(undefined);
        // @ts-ignore
        onOk(id, REASONS[reason], feedback);
    };

    // @ts-ignore
    const handleOptionChange = (event) => setReason(event.target.value);
    // @ts-ignore
    const handleFeedbackChange = (event) => setFeedback(event.target.value);

    return (
        <Modal centered closable={false} okButtonProps={{ disabled: reason === undefined }} okText="Reject" onCancel={handleCancel} onOk={handleOk} title="Reject SOS" visible>
            <p>Please provide the reason you (the admin) are rejecting this SOS.</p>
            <Radio.Group onChange={handleOptionChange} value={reason}>
                <Radio className={styles.radio} value={1}>
                    {REASONS[1]}
                </Radio>
                <Radio className={styles.radio} value={2}>
                    {REASONS[2]}
                </Radio>
                <Radio className={styles.radio} value={3}>
                    {REASONS[3]}
                </Radio>
                <Radio className={styles.radio} value={4}>
                    {REASONS[4]}
                </Radio>
                <Input.TextArea className={styles.feedback} onChange={handleFeedbackChange} />
            </Radio.Group>
        </Modal>
    );
};

export { RejectModalView };
