import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useUsersByIdMessagesQuery = (ids) =>
    useQuery(
        gql`
            query GetAllByUserIdMessages($ids: [Int!]) {
                sosGetAllByUserIdMessages(ids: $ids) {
                    user_id
                    sos_id
                    distance
                    latitude
                    longitude
                    isDeleted
                    message
                    status
                    created_at
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
            variables: { ids: ids },
        }
    );

export { useUsersByIdMessagesQuery };
