import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { donatePopupFields, GetVerificationPopupMsg, VerificationPopupFields } from '@/components/atoms';
import { withErrorsFeedback } from '@/forms';
import { SosWhy, useSosNewContainerCreateSosMutation } from '@/graphql';
import { useMeQuery } from '@/hooks';

import { SosNewView } from './SosNewView';

type Values = {
    distance: number;
    lat: number;
    lng: number;
    location: string;
    message: string;
    why: SosWhy;
};

const defaultValues: Values = {
    distance: 10,
    lat: 54.7781,
    lng: -3.436,
    location: '',
    message: '',
    why: SosWhy.AtHome,
};

const SosNewContainer = () => {
    const history = useHistory();
    const [values, setValues] = useState<Values>(defaultValues);
    const [createSos] = useSosNewContainerCreateSosMutation();
    const { data, error, loading } = useMeQuery();
    const handleChange = (values: Values) => setValues(values);

    useEffect(() => {
        if (data && data.me && (!data.me.emailVerified || !data.me.mobileVerified)) {
            Modal.warning({
                ...VerificationPopupFields,
                content: GetVerificationPopupMsg(data.me),
                onOk: () => {
                    history.push('/profile');
                },
            });
        }
    }, [data]);

    const handleSubmit = withErrorsFeedback(async ({ message, why }: Values) => {
        const { distance, lat: latitude, lng: longitude, location } = values;

        const { data } = await createSos({
            variables: {
                input: {
                    distance,
                    latitude,
                    longitude,
                    location,
                    message,
                    why,
                },
            },
        });

        const hasRecipients = data?.createSos;

        if (hasRecipients) {
            Modal.success({
                title: 'Congratulations! SOS has been submitted *',
                content: (
                    <>
                        <p>You will receive any responses via Email and/or SMS.</p>
                        <p>
                            <em>* SOS requests are submitted for approval before being delivered to other users.</em>
                        </p>
                    </>
                ),
                onOk: () => {
                    Modal.confirm({
                        ...donatePopupFields,
                    });
                    history.push('/sos/my-sos');
                },
            });
        } else {
            Modal.error({
                title: 'No Available Recipients',
                content: 'Sorry, there are no registered users available to receive your request in the location provided. You could try increasing your travelling distance.',
            });
        }
    });
    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;
    return <SosNewView onChange={handleChange} onSubmit={handleSubmit} values={values} />;
};

export { SosNewContainer };
export type { Values as SosNewContainerValues };
