import { Card as CardAntDesign } from 'antd';
import { CardProps } from 'antd/lib/card';

import styles from './Card.module.css';

type Props = CardProps;

const Card = ({ className, ...props }: Props) => <CardAntDesign bordered={false} className={`${styles.card} ${className}`} {...props} />;

export { Card };
