import { gql, useMutation } from '@apollo/client';

const useResendVerifyMobileMutation = (options) =>
    useMutation(
        gql`
            mutation ResendVerifyMobileMutation {
                resendVerifyMobile
            }
        `,
        {
            ...options,
        }
    );

export { useResendVerifyMobileMutation };
