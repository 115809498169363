import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const toLatLng = async (location: string) => {
    const details = await geocodeByAddress(location);
    const { lat, lng } = await getLatLng(details[0]);

    return { lat, lng };
};

export { toLatLng };
