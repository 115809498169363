import { Spinner } from '@/components/atoms';
import { LayoutCentred } from '@/components/templates';

const PageLoading = () => (
    <LayoutCentred>
        <Spinner />
    </LayoutCentred>
);

export { PageLoading };
