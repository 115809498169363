import { Alert, Button } from 'antd';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';

import { PageCentredFullScreen } from '@/components/templates';

const VerifyEmailView = ({ loading, success }) => {
    const content = loading ? (
        <Alert description="We are verifying your email address..." message="Please wait" showIcon type="info" />
    ) : success ? (
        <Alert description="Your email address has been verified. You can safely close this window." message="Success!" showIcon type="success" />
    ) : (
        <Alert description="We were unable to verify your email address. Please try again later." message="Something went wrong" showIcon type="error" />
    );

    return (
        <PageCentredFullScreen title="Email verification">
            {content}
            <Link to="/login">
                <Button block type="link">
                    Go to Login
                </Button>
            </Link>
        </PageCentredFullScreen>
    );
};

VerifyEmailView.propTypes = {
    loading: bool.isRequired,
    success: bool.isRequired,
};

export { VerifyEmailView };
