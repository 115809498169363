import { Button, Result } from 'antd';
import { func } from 'prop-types';

const PageError = ({ onRetry }) => (
    <Result
        extra={
            <Button onClick={onRetry} size="large" type="primary">
                Retry
            </Button>
        }
        status="500"
        subTitle="Keep calm, click refresh in a moment to try again."
        title="Opss, we can't load that right now..."
    />
);

PageError.propTypes = {
    onRetry: func.isRequired,
};

export { PageError };
