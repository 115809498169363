import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useResetPasswordMutation } from './hooks';
import { ResetPasswordView } from './ResetPasswordView';

const ResetPasswordContainer = () => {
    const { token } = useParams();
    const [success, setSuccess] = useState(false);
    const [resetPassword] = useResetPasswordMutation({
        onCompleted: ({ resetPassword }) => {
            setSuccess(resetPassword);
        },
    });

    const handleSubmit = async ({ password }, formikBag) => {
        const { data } = await resetPassword({ variables: { token, password } });

        if (!data.resetPassword) {
            formikBag.setErrors({
                password: '',
                passwordConfirm: 'Password reset expired. Please try again.',
            });
        }
    };

    return <ResetPasswordView onSubmit={handleSubmit} success={success} />;
};

export { ResetPasswordContainer };
