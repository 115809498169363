import { startCase } from 'lodash';

import { AttributeUniqueError, isAttributeErrorCode } from './errorCodes';

const getErrorMessage = ({ extensions: { code, data } }) => {
    switch (code) {
        case AttributeUniqueError:
            return `${startCase(data.attribute)} already exists. Please enter a different value.`;
        default:
            return 'Unknown error';
    }
};

const transformErrors = (graphQLErrors) => {
    const errors = {};

    if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
            if (isAttributeErrorCode(graphQLError.extensions.code)) {
                errors[graphQLError.extensions.data.attribute] = getErrorMessage(graphQLError);
            }
        });
    }

    return errors;
};

export { transformErrors };
