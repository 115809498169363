import { gql, useMutation } from '@apollo/client';

const useSendMarketplaceItemContactMessageMutation = (options) =>
    useMutation(
        gql`
            mutation SendMarketplaceItemContactMessageMutation($input: SendMarketplaceItemContactMessageInput!) {
                sendMarketplaceItemContactMessage(input: $input)
            }
        `,
        options
    );

export { useSendMarketplaceItemContactMessageMutation };
