import './index.css';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDom from 'react-dom';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter } from 'react-router-dom';

import { GlobalError } from './components/organisms';
import { App } from './components/templates';
import { client } from './graphql';
import { reportWebVitals } from './reportWebVitals';

Sentry.init({
    beforeSend: (event) => (process.env.NODE_ENV === 'production' ? event : null),
    dsn: 'https://1283d9a359ec405a814c4504dcd2e13a@o466255.ingest.sentry.io/5480349',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDom.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
            <Sentry.ErrorBoundary dialogOptions={{ title: 'Oops! Something went wrong.' }} fallback={<GlobalError />} showDialog>
                <ModalProvider>
                    <App />
                </ModalProvider>
            </Sentry.ErrorBoundary>
        </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
