import { useEffect } from 'react';
import ReactGa from 'react-ga';

ReactGa.initialize('UA-81524201-1');

const withAnalytics = (WrappedComponent, options = {}) => {
    const trackPage = (page) => {
        if (process.env.NODE_ENV === 'production') {
            ReactGa.set({
                page,
                ...options,
            });
            ReactGa.pageview(page);
        }
    };

    const HOC = (props) => {
        useEffect(() => trackPage(props.location.pathname), [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };

    return HOC;
};

export { withAnalytics };
