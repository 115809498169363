import { Col, Row } from 'antd';

import styles from './LayoutView.module.css';

const LayoutView = ({ children }) => {
    const gutterProps = { lg: 1, md: 0, sm: 0, xl: 2, xs: 0, xxl: 3 };
    const contentProps = { lg: 22, md: 24, sm: 24, xl: 20, xs: 24, xxl: 18 };

    return (
        <Row className={styles.row}>
            <Col {...gutterProps} />
            <Col {...contentProps}>{children}</Col>
            <Col {...gutterProps} />
        </Row>
    );
};

export { LayoutView };
