import { gql, useMutation } from '@apollo/client';

const useForgotPasswordMutation = (options) =>
    useMutation(
        gql`
            mutation ForgotPasswordMutation($email: String!) {
                forgotPassword(email: $email)
            }
        `,
        {
            ...options,
        }
    );

export { useForgotPasswordMutation };
