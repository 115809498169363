import SosIcon from '@/assets/icons/sos.png';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';

import { AdminSosList12Months, AdminSosList30Days, AdminSosListDetails, AdminSosListMap, AdminSosListOverview, AdminSosListToday } from './components';

const AdminSosListLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ src: SosIcon }} onBack={onBack} title="SOS Statistics" />
        <Layout>{children}</Layout>
    </>
);

const AdminSosListData = ({ data }) => {
    const totalOverview = data.sosCount;
    const todayTotal = data.sosCountDays[data.sosCountDays.length - 1];
    const total30Days = data.sosCountDays.reduce((currentCount, dayCount) => currentCount + dayCount);
    const total12Months = data.sosCountMonths.reduce((currentCount, monthCount) => currentCount + monthCount);

    return (
        <AdminSosListLayout>
            <AdminSosListOverview total={totalOverview} />
            <AdminSosListMap sos={data.sos} />
            <AdminSosListToday total={todayTotal} />
            <AdminSosList30Days total={total30Days} values={data.sosCountDays} />
            <AdminSosList12Months total={total12Months} values={data.sosCountMonths} />
            <AdminSosListDetails values={data.sos} />
        </AdminSosListLayout>
    );
};

const AdminSosListView = {
    Error: ({ onBack, ...props }) => (
        <AdminSosListLayout onBack={onBack}>
            <PageError {...props} />
        </AdminSosListLayout>
    ),
    Data: AdminSosListData,
    Loading: ({ onBack }) => (
        <AdminSosListLayout onBack={onBack}>
            <PageLoading />
        </AdminSosListLayout>
    ),
};

export { AdminSosListView };
