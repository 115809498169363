import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useMarketplaceListSearchBarQuery = () =>
    useQuery(
        gql`
            query MarketplaceListSearchBarQuery {
                marketplaceCategories {
                    id
                    name
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
        }
    );

export { useMarketplaceListSearchBarQuery };
