import { Card, Col, Row, Spin } from 'antd';

import { useMarketplaceNewDetailsContainerQuery } from '@/graphql';

import { MarketplaceNewContainerValues } from '../../MarketplaceNewContainer';
import { MarketplaceNewDetailsView } from './MarketplaceNewDetailsView';

type Props = {
    initialValues: MarketplaceNewContainerValues;
    onBack: () => void;
    onNext: (values: MarketplaceNewContainerValues) => void;
};

const MarketplaceNewDetailsContainer = ({ initialValues, onBack, onNext }: Props) => {
    const { data, error, loading } = useMarketplaceNewDetailsContainerQuery();

    const handleSubmit = (values: MarketplaceNewContainerValues) => {
        if (data) {
            onNext({
                ...values,
                category: data.marketplaceCategories.find((category) => category.id === values.categoryId),
            });
        }
    };

    if (loading)
        return (
            <Row>
                <Col md={{ span: 12, offset: 6 }}>
                    <Card style={{ textAlign: 'center' }}>
                        <Spin size="large" tip="Loading..." />
                    </Card>
                </Col>
            </Row>
        );
    if (error) return <>Error</>;
    if (data) return <MarketplaceNewDetailsView categories={data.marketplaceCategories} initialValues={initialValues} onBack={onBack} onSubmit={handleSubmit} />;
    return null;
};

export { MarketplaceNewDetailsContainer };
