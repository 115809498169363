import { gql, useMutation } from '@apollo/client';

const useDeleteMarketplaceItemAsAdminMutation = (options) =>
    useMutation(
        gql`
            mutation DeleteMarketplaceItemAsAdminMutation($id: ID!, $reason: String!, $feedback: String) {
                deleteMarketplaceItemAsAdmin(id: $id, reason: $reason, feedback: $feedback) {
                    id
                }
            }
        `,
        {
            update(cache, { data: { deleteMarketplaceItemAsAdmin } }) {
                cache.modify({
                    fields: {
                        marketplaceItems(existingMarketplaceItemRefs, { readField }) {
                            return existingMarketplaceItemRefs.filter((marketplaceItemRef) => readField('id', marketplaceItemRef) !== deleteMarketplaceItemAsAdmin.id);
                        },
                    },
                });
            },
            ...options,
        }
    );

export { useDeleteMarketplaceItemAsAdminMutation };
