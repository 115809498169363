import { gql, useMutation } from '@apollo/client';

const useUpdateMeMutation = (options) =>
    useMutation(
        gql`
            mutation UpdateMeMutation($input: UpdateMeInput!) {
                updateMe(input: $input) {
                    email
                    emailVerified
                    id
                    image {
                        id
                        url
                    }
                    location
                    mobile
                    mobileVerified
                    name
                    sosReceive
                    sosReceiveEmail
                    sosReceiveMobile
                }
            }
        `,
        {
            ...options,
        }
    );

export { useUpdateMeMutation };
