import { EyeOutlined } from '@ant-design/icons';
import { Alert, Button, List, Result, Space } from 'antd';
import { default as loadImage } from 'blueimp-load-image';
import { useEffect, useState } from 'react';

import { MarketplaceItem } from '@/components/atoms';
import { MarketplaceItemStatus } from '@/graphql';

import { MarketplaceNewContainerValues } from '../../MarketplaceNewContainer';
import styles from './MarketplaceNewPreview.module.css';

type Props = {
    onBack: () => void;
    onSubmit: () => Promise<void>;
    values: MarketplaceNewContainerValues;
};

const MarketplaceNewPreview = ({ onBack, onSubmit, values }: Props) => {
    const [imageThumbUrl, setImageThumbUrl] = useState<string>();
    const [images_list, setImageList] = useState<any[]>();
    const [inProgress, setInProgress] = useState(false);
    // const images_list = values.images ? values.images.map(async function(img:any) {
    //   const file = img.originFileObj;
    // }) : [undefined];

    const getFileDataUrl = (file: any) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(file);
        });
    };

    const getAllFileDataUrls = async (values: any) => {
        let allPromises: any[] = [];
        values.images.map((file: any) => {
            allPromises.push(getFileDataUrl(file.originFileObj));
        });
        return await Promise.all(allPromises);
    };

    useEffect(() => {
        if (values.images && values.images.length) {
            if (!images_list || images_list?.length == 0)
                getAllFileDataUrls(values).then((dataUrls) => {
                    let dataUrls_: any = [];
                    dataUrls.forEach((dataUrl) => {
                        dataUrls_?.push({
                            url: dataUrl,
                        });
                    });
                    setImageList(dataUrls_);
                });
            const file = values.images[0].originFileObj;
            if (file) {
                loadImage(
                    file,
                    (image) => {
                        if (image instanceof HTMLCanvasElement) {
                            setImageThumbUrl(image.toDataURL());
                        }
                    },
                    {
                        canvas: true,
                        orientation: true,
                        maxWidth: 600,
                    }
                );
            }
        } else {
            setImageThumbUrl(undefined);
        }

        return () => {
            setImageThumbUrl(undefined);
        };
    }, []);

    const handleSubmit = async () => {
        setInProgress(true);
        await onSubmit();
        setInProgress(false);
    };

    const calculateAmount = (percentage: number, price: number) => Math.ceil((price * percentage) / 100);

    const getUnitAmount = () => {
        let amount;
        let price = values.price * 100;
        const threshold = Number(process.env.REACT_APP_LISTING_THRESHOLD) * 100;
        const percentage = Number(process.env.REACT_APP_LISTING_PERCENTAGE) ?? 10;
        if (price > 0 && price <= threshold) {
            amount = Number(process.env.REACT_APP_LISTING_MINIMUM_AMOUNT) ?? 10;
        } else {
            amount = calculateAmount(percentage, price);
        }
        return (amount / 100).toFixed(2);
    };

    const getDescription = () => {
        if (!values.isFree && values.price > 0) {
            return (
                <>
                    You have added a product to be <b>sold for £{values.price.toFixed(2)}</b>, to list this item we request a <b>compulsory donation of £{getUnitAmount()}</b> to contribute towards the running costs of this service. Once the payment is completed, your product will be verified within 24 - 48 hours. You will receive an email when its status changes and the product goes live.
                </>
            );
        } else {
            return 'This usually takes place within 24 hours. You will receive an email when its status changes.';
        }
    };

    const getTitle = () => {
        if (!values.isFree && values.price > 0) {
            return `Thank you for submitting your product to the Skiggle Marketplace, Your Item will be reviewed by an admin shortly. Skiggle is a registered charity and relies on donations to support the running costs of the Marketplace`;
        } else {
            return 'Your Item Will Be Reviewed by a Administrator';
        }
    };

    return (
        <Result
            extra={
                <Space size="large">
                    <Button disabled={inProgress} onClick={onBack} size="large">
                        Back
                    </Button>
                    <Button disabled={inProgress} loading={inProgress} onClick={handleSubmit} size="large" type="primary">
                        Submit
                    </Button>
                </Space>
            }
            icon={<EyeOutlined />}
            subTitle={
                <>
                    <>Check your item preview below before you submit. Items cannot be edited after being successfully submitted.</>
                    <div className={styles.preview}>
                        <List dataSource={[values]} itemLayout="vertical" renderItem={({ images, price, ...props }) => <MarketplaceItem {...props} createdAt={new Date().toISOString()} image={imageThumbUrl} images={images_list} price={price * 100} status={MarketplaceItemStatus.Approved} />} size="large" />
                    </div>
                    <Alert description={getDescription()} message={getTitle()} showIcon style={{ marginTop: 15 }} type="warning" />
                </>
            }
            title="Almost there!"
        />
    );
};

export { MarketplaceNewPreview };
