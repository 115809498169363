import 'yup-phone';

import { Alert, Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Please enter a password'),
    passwordConfirm: Yup.string()
        .required('Please enter a confirm password')
        .test('passwords-match', "Passwords don't match", function (value) {
            return this.parent.password === value;
        }),
});

const ResetPasswordView = ({ success, onSubmit }) => {
    const content = success ? (
        <Alert description="Your password has been successfully reset." message="Success!" showIcon type="success" />
    ) : (
        <Formik initialValues={{}} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form size="large">
                <Form.Item name="password">
                    <Input.Password disabled={success} name="password" placeholder="New password" />
                </Form.Item>

                <Form.Item name="passwordConfirm">
                    <Input.Password disabled={success} name="passwordConfirm" placeholder="Re-enter your new password" />
                </Form.Item>

                <Form.Item name>
                    <SubmitButton block>Reset password</SubmitButton>
                </Form.Item>
            </Form>
        </Formik>
    );

    return (
        <PageCentredFullScreen title="Reset password">
            {content}
            <Link to="/login">
                <Button block type="link">
                    Go to Login
                </Button>
            </Link>
        </PageCentredFullScreen>
    );
};

ResetPasswordView.propTypes = {
    onSubmit: func.isRequired,
    success: bool,
};

export { ResetPasswordView };
