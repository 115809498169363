import { Select, Spin } from 'antd';
import { Field } from 'formik-antd';
import { forwardRef } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

const LocationInput = forwardRef(({ placeholder, name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...props }, ref) => (
    <Field fast={fast} name={name} validate={validate}>
        {({ field: { value, onChange, onBlur } }) => {
            if (!window.google) return null; // Check if Google Maps has loaded

            return (
                <PlacesAutocomplete
                    name={name}
                    onChange={(value) => {
                        const event = { target: { value, name } };
                        onChange(event);
                        $onChange && $onChange(event);
                    }}
                    ref={ref}
                    value={value}
                    {...props}
                    debounce={300}
                    searchOptions={{
                        location: new google.maps.LatLng(54.165, -4.417), // eslint-disable-line
                        radius: 55000,
                    }}
                >
                    {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => {
                        const { onChange, ...inputProps } = getInputProps({
                            allowClear: true,
                            filterOption: false,
                            placeholder,
                            showSearch: true,
                            notFoundContent: loading ? <Spin size="small" /> : 'Sorry, no locations were found',
                            onClear: () => onChange({ target: { value: undefined } }),
                            onSearch: (value) => onChange({ target: { value } }),
                            onSelect: (value) => {
                                const activeSuggestion = suggestions.find((suggestion) => suggestion.placeId === value);
                                const { onClick } = getSuggestionItemProps(activeSuggestion);

                                onClick();
                            },
                            name,
                        });

                        return (
                            <Select
                                {...inputProps}
                                onBlur={(event) => {
                                    onBlur('location')(event);
                                    $onBlur && $onBlur(event);
                                }}
                            >
                                {suggestions.map((suggestion) => (
                                    <Select.Option {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                                        {suggestion.description}
                                    </Select.Option>
                                ))}
                            </Select>
                        );
                    }}
                </PlacesAutocomplete>
            );
        }}
    </Field>
));

export { LocationInput };
