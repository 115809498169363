import { gql, useMutation } from '@apollo/client';

const useUpdateUserStatusMutation = (options) =>
    useMutation(
        gql`
            mutation UpdateUserStatusMutation($id: Int!, $status: String!) {
                updateStatus(id: $id, status: $status) {
                    id
                }
            }
        `,
        {
            ...options,
        }
    );

export { useUpdateUserStatusMutation };
