import './App.less';

import { BackTop, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ScrollToTop } from '@/components/atoms';
import { ForgotPassword, Locked, Login, ResetPassword, SignUp, SosSurvey, VerifyEmail, VerifyMobile } from '@/components/pages';
import { Frame } from '@/components/templates';
import { withAnalytics } from '@/routes';

const App = () => (
    <Layout>
        <Helmet defaultTitle="Skiggle - a community sharing in times of need" titleTemplate="Skiggle - %s" />
        <Switch>
            <Route component={withAnalytics(ForgotPassword)} exact path="/forgot-password" />
            <Route component={withAnalytics(Locked)} exact path="/locked" />
            <Route component={withAnalytics(Login)} exact path="/login" />
            <Redirect exact from="/auth/login" to="/login" />
            <Route component={withAnalytics(ResetPassword)} exact path="/reset-password/:token" />
            <Route component={withAnalytics(SignUp)} exact path="/sign-up" />
            <Route component={withAnalytics(SosSurvey)} exact path="/sos/survey/:token/:feedback" />
            <Route component={withAnalytics(VerifyEmail)} exact path="/verify-email/:token" />
            <Route component={withAnalytics(VerifyMobile)} exact path="/verify-mobile" />
            <Route component={Frame} />
        </Switch>
        <ScrollToTop />
        <BackTop />
    </Layout>
);

export { App };
