import { gql, useQuery } from '@apollo/client';

const useAdminUsersListQuery = () =>
    useQuery(
        gql`
            query AdminUsersListQuery {
                usersCount
                usersCountDays(days: 30)
                usersCountMonths(months: 12)
            }
        `
    );

export { useAdminUsersListQuery };
