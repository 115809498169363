import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation, useMarketplaceMyItemsContainerPaidMarketplaceItemMutation, useMarketplaceMyItemsContainerQuery, useMarketplaceMyItemsContainerRelistMarketplaceItemMutation, useMarketplaceMyItemsContainerRenewMarketplaceItemMutation } from '@/graphql';
import { viewFactory } from '@/utils';

import { MarketplaceMyItemsView } from './MarketplaceMyItemsView';

const MarketplaceMyItemsContainer = () => {
    const history = useHistory();
    const query = useMarketplaceMyItemsContainerQuery();
    const [deleteMarketplaceItem] = useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation({
        update(cache, { data: { deleteMarketplaceItem } }) {
            cache.modify({
                fields: {
                    marketplaceItems(existingMarketplaceItemRefs, { readField }) {
                        return existingMarketplaceItemRefs.filter((marketplaceItemRef) => readField('id', marketplaceItemRef) !== deleteMarketplaceItem.id);
                    },
                    me() {
                        const query = gql`
                            query me {
                                me {
                                    id
                                    myMarketplaceItems {
                                        id
                                    }
                                }
                            }
                        `;

                        const data = cache.readQuery({ query });

                        const updatedMyMarketplaceItems = data.me.myMarketplaceItems.filter((marketplaceItem) => marketplaceItem.id !== deleteMarketplaceItem.id).map(({ id }) => ({ __ref: `MarketplaceItem:${id}` }));

                        cache.writeFragment({
                            id: `User:${data.me.id}`,
                            fragment: gql`
                                fragment meUser on User {
                                    myMarketplaceItems
                                }
                            `,
                            data: {
                                myMarketplaceItems: updatedMyMarketplaceItems,
                                __typename: 'User',
                            },
                        });
                    },
                },
            });
        },
    });
    const [renewMarketplaceItem] = useMarketplaceMyItemsContainerRenewMarketplaceItemMutation();

    const [relistMarketplaceItem] = useMarketplaceMyItemsContainerRelistMarketplaceItemMutation();
    const [paidMarketplaceItem] = useMarketplaceMyItemsContainerPaidMarketplaceItemMutation({
        onCompleted: (data) => {
            query.refetch();
        },
    });
    return viewFactory(MarketplaceMyItemsView, query, {
        onBack: () => history.push('/marketplace'),
        onDelete: ({ id }) =>
            deleteMarketplaceItem({
                variables: { id },
            }),
        onRenew: (id) =>
            renewMarketplaceItem({
                variables: { id },
            }),
        onRelist: (id) =>
            relistMarketplaceItem({
                variables: { id },
            }),
        onPaid: ({ id, session, price, session_id }) =>
            paidMarketplaceItem({
                variables: { id, session, price, session_id },
            }),
    });
};

export { MarketplaceMyItemsContainer };
