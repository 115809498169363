import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { useSosEditContainerEditSosItemMutation, useSosEditContainerQuery } from '@/graphql';
import { viewFactory } from '@/utils';

import { SosEditView } from './SosEditView';

const SosEditContainer = () => {
    // @ts-ignore
    const { id } = useParams();
    const history = useHistory();
    const query = useSosEditContainerQuery({ variables: { id } });
    const [editSos] = useSosEditContainerEditSosItemMutation({
        onError: (error) => message.error(error.message),
    });

    const handleBackToList = () => history.push('/sos/my-sos');
    const handleSubmit = async (values: { message: string }) => {
        await editSos({
            variables: {
                input: {
                    id,
                    message: values.message,
                },
            },
        });

        handleBackToList();
    };

    // @ts-ignore
    return viewFactory(SosEditView, query, {
        onBack: handleBackToList,
        onSubmit: handleSubmit,
    });
};

export { SosEditContainer };
