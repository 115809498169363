import { gql, useQuery } from '@apollo/client';

const useMarketplaceMyFavouritesQuery = (options) =>
    useQuery(
        gql`
            query MarketplaceMyFavouritesQuery {
                me {
                    favouriteMarketplaceItems {
                        category {
                            id
                            name
                        }
                        createdAt
                        description
                        id
                        images {
                            id
                            uuid
                            url
                        }
                        location
                        price
                        title
                    }
                    id
                }
            }
        `,
        {
            notifyOnNetworkStatusChange: true,
            ...options,
        }
    );

export { useMarketplaceMyFavouritesQuery };
