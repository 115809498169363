import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';

import { Card } from '@/components/atoms';
import { Sos } from '@/graphql';

import { Map } from './Map';

type Props = {
    sos: Sos[];
};

const AdminSosListMap = ({ sos }: Props) => (
    <Row gutter={[0, 32]}>
        <Col span={24}>
            <Card
                title={
                    <Space>
                        <EnvironmentOutlined /> Send Locations (SOS V2 Deployed 3rd May 2021)
                    </Space>
                }
            >
                <Map containerElement={<div style={{ height: `400px` }} />} locations={sos.map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }))} mapElement={<div style={{ height: `100%` }} />} />
            </Card>
        </Col>
    </Row>
);

export { AdminSosListMap };
