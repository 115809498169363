import { Modal } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, Input } from 'formik-antd';
import { bool, func } from 'prop-types';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    shareMy: Yup.array().test('containsEmailOrMobile', 'Either email or mobile must be shared', (value) => value.includes('email') || value.includes('mobile')),
});

const SendMessageModal = ({ onCancel, onSubmit, visible }) => {
    return (
        <Formik initialValues={{ message: '', shareMy: ['email', 'name', 'mobile'] }} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isSubmitting, isValid, submitForm }) => (
                <Modal centered closable={false} confirmLoading={isSubmitting} okButtonProps={{ disabled: !isValid }} okText="Send" onCancel={onCancel} onOk={submitForm} title="Send Message" visible={visible}>
                    <Form>
                        <p>Notify the member you are interested in this item by chosing which details to share with them:</p>
                        <Form.Item label="Share my" name="shareMy">
                            <Checkbox.Group
                                name="shareMy"
                                options={[
                                    { label: 'Name', value: 'name' },
                                    { label: 'Email', value: 'email' },
                                    { label: 'Mobile', value: 'mobile' },
                                ]}
                            />
                        </Form.Item>

                        <p>Add a message:</p>

                        <Form.Item name="message">
                            <Input.TextArea name="message" />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

SendMessageModal.propTypes = {
    onCancel: func.isRequired,
    onSubmit: func.isRequired,
    visible: bool.isRequired,
};

export { SendMessageModal };
