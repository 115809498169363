import { Redirect } from 'react-router';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { MarketplaceItemStatus } from '@/graphql';

import { MarketplaceItemInputs } from './MarketplaceItemInputs';

const MarketplaceMyItemsLayout = ({ children, onBack, title = 'Edit' }) => (
    <>
        <PageHeader icon={{ src: MarketplaceIcon }} onBack={onBack} title={title} />
        <Layout>
            <Card>{children}</Card>
        </Layout>
    </>
);

const MarketplaceEditData = ({ data, onBack, onSubmit }) => {
    if (data.marketplaceItem.status !== MarketplaceItemStatus.ChangeRequired) return <Redirect to="/marketplace/my-items" />;

    const { __typename, category, price, status, ...marketplaceItem } = data.marketplaceItem;
    const initialValues = { categoryId: category.id, isFree: price === 0, price: price / 100, ...marketplaceItem };

    return (
        <MarketplaceMyItemsLayout onBack={onBack} title={`Edit '${data.marketplaceItem.title}'`}>
            <Card>
                <MarketplaceItemInputs categories={data.marketplaceCategories} initialValues={initialValues} onBack={onBack} onSubmit={onSubmit} />
            </Card>
        </MarketplaceMyItemsLayout>
    );
};

const MarketplaceEditView = {
    Error: ({ onBack, ...props }) => (
        <MarketplaceMyItemsLayout onBack={onBack}>
            <PageError {...props} />
        </MarketplaceMyItemsLayout>
    ),
    Data: MarketplaceEditData,
    Loading: ({ onBack }) => (
        <MarketplaceMyItemsLayout onBack={onBack}>
            <PageLoading />
        </MarketplaceMyItemsLayout>
    ),
};

export { MarketplaceEditView };
