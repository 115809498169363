import { Modal, Radio } from 'antd';
import { bool, func } from 'prop-types';
import { useState } from 'react';
import ReactGa from 'react-ga';

import styles from './DeleteModalView.module.css';

const REASONS = {
    1: 'Given Away on Skiggle Marketplace',
    2: 'Given Away Elsewhere',
    3: 'Sold on Skiggle Marketplace',
    4: 'Sold Elsewhere',
    5: 'No Longer for Sale',
    6: 'Other',
};

const DeleteModalView = ({ onCancel, onOk, visible }) => {
    const [reason, setReason] = useState(undefined);

    const handleCancel = () => {
        setReason(undefined);
        onCancel();
    };

    const handleOk = () => {
        setReason(undefined);
        ReactGa.event({
            action: 'Deleted',
            category: 'Marketplace Item',
            label: REASONS[reason],
        });
        onOk();
    };

    const handleOptionChange = (event) => setReason(event.target.value);

    return (
        <Modal centered closable={false} okButtonProps={{ disabled: reason === undefined }} okText="Delete Item" okType="danger" onCancel={handleCancel} onOk={handleOk} title="Delete Item?" visible={visible}>
            <p>Please provide the reason you are deleting this item.</p>
            <Radio.Group onChange={handleOptionChange} value={reason}>
                <Radio className={styles.radio} value={1}>
                    I have given it away on Skiggle Marketplace
                </Radio>
                <Radio className={styles.radio} value={2}>
                    I have given it away elsewhere
                </Radio>
                <Radio className={styles.radio} value={3}>
                    I have sold it on Skiggle Marketplace
                </Radio>
                <Radio className={styles.radio} value={4}>
                    I have sold it elsewhere
                </Radio>
                <Radio className={styles.radio} value={5}>
                    No longer for sale
                </Radio>
                <Radio className={styles.radio} value={6}>
                    Other
                </Radio>
            </Radio.Group>
        </Modal>
    );
};

DeleteModalView.propTypes = {
    onCancel: func.isRequired,
    onOk: func.isRequired,
    visible: bool.isRequired,
};

export { DeleteModalView };
