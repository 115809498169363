import { Divider } from 'antd';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';

const UsersListView = ({ children }) => {
    return (
        <>
            <PageHeader icon={{ src: MarketplaceIcon }} title="Users List" />
            <Layout>
                <Card>
                    {children.searchBar}
                    <Divider dashed />
                    {children.results}
                </Card>
            </Layout>
        </>
    );
};

export { UsersListView };
