import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { message } from 'antd';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import { FORBIDDEN, InternalServerError } from './errorCodes';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ extensions }) => {
                    if (extensions?.exception?.code === FORBIDDEN) {
                        message.error('You are blocked by Admin. Please contact through email for further details!');
                    } else if (extensions?.code === InternalServerError) {
                        message.error('Oops, something went wrong. Please try again later.');
                    }
                });
            }
        }),
        createUploadLink({
            credentials: 'include',
            uri: process.env.REACT_APP_API_URL,
        }),
    ]),
});

export { client };
