import { useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { withErrorsFeedback } from '@/forms';

import { SignUpDetails, SignUpVerification } from './components';
import { useSignUpMutation, useVerifyMobileMutation } from './hooks';

let FingerprintJS = require('@fingerprintjs/fingerprintjs-pro');
// let Botd = require("@fpjs-incubator/botd-agent");
const _ = require('dotenv').config();

async function get_user_info() {
    return new Promise(async (resolve, reject) => {
        try {
            const fpPromise = FingerprintJS.load({
                token: process.env.REACT_APP_FINGERPRINTJS_TOKEN,
                region: 'eu',
                endpoint: process.env.REACT_APP_END_POINT,
            });

            // Fingerprint
            const fp = await fpPromise;
            var fp_resp = await fp.get({
                extendedResult: true,
            });
            resolve({
                ip: fp_resp.ip,
                visitor_id: fp_resp.visitorId,
                country: fp_resp.ipLocation?.country?.name || '',
                city: fp_resp.ipLocation?.city?.name || '',
                postal_code: fp_resp.ipLocation?.postalCode || '',
                browser_name: fp_resp.browserName,
            });
        } catch (e) {
            reject({
                ip: '',
                visitor_id: '',
                country: '',
                city: '',
                postal_code: '',
                browser_name: '',
            });
        }
    });
}

const SignUpContainer = () => {
    const [user, setUser] = useState({});
    const [verifyMobileSuccess, setVerifyMobileSuccess] = useState(false);
    const [step, setStep] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [signUp] = useSignUpMutation({
        onCompleted: ({ signUp }) => {
            setUser(signUp);
            setStep(1);
        },
    });
    const [verifyMobile] = useVerifyMobileMutation({
        onCompleted: ({ verifyMobile }) => {
            setVerifyMobileSuccess(verifyMobile);
        },
    });

    const handleSignUp = withErrorsFeedback(async ({ email, location, mobile, name, password, sosReceive }, formikBag) => {
        const details = await geocodeByAddress(location);
        const { lat, lng } = await getLatLng(details[0]);
        try {
            let userInfo = await get_user_info();

            await signUp({
                variables: {
                    input: {
                        email,
                        latitude: lat,
                        location,
                        longitude: lng,
                        mobile,
                        name,
                        password,
                        sosReceive,
                        visitor_id: userInfo.visitor_id,
                        country: userInfo.country,
                        city: userInfo.city,
                        postal_code: userInfo.postal_code,
                        browser_name: userInfo.browser_name,
                        ip: userInfo.ip,
                    },
                },
            });
        } catch (error) {
            if (error.message) {
                if (error.message.includes('email')) {
                    formikBag.setErrors({
                        email: error.message,
                    });
                } else {
                    setErrorMessage(error.message);
                }
            }
        }
    });

    const handleVerifyMobile = async ({ code }, formikBag) => {
        const { data } = await verifyMobile({ variables: { code } });

        if (!data.verifyMobile) {
            formikBag.setErrors({
                code: 'Invalid code. Please try again.',
            });
        }
    };

    return (
        <>
            {step === 0 && <SignUpDetails errorMessage={errorMessage} onSignUp={handleSignUp} />}
            {step === 1 && <SignUpVerification email={user.email} mobile={user.mobile} onVerifyMobile={handleVerifyMobile} verifyMobileSuccess={verifyMobileSuccess} />}
        </>
    );
};

export { SignUpContainer };
