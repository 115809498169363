import 'yup-phone';

import { HeartTwoTone } from '@ant-design/icons';
import { Alert } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, Input, SubmitButton, Switch } from 'formik-antd';
import { func } from 'prop-types';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';
import { LocationInput } from '@/forms/components';
import { email, location, mobile, name, password, passwordConfirm } from '@/forms/validationSchema';

const validationSchema = Yup.object().shape({
    acceptPrivacyPolicy: Yup.bool().oneOf([true]),
    acceptTermsConditions: Yup.bool().oneOf([true]),
    email,
    location,
    mobile,
    name,
    password: password.required('Please enter a password'),
    passwordConfirm: passwordConfirm.required('Please enter a confirm password'),
    receiveSos: Yup.boolean(),
});

const SignUpDetailsView = ({ onSignUp, errorMessage }) => (
    <PageCentredFullScreen description="Join the Skiggle community - it's free." title="Sign up">
        <Formik
            initialValues={{
                acceptPrivacyPolicy: false,
                acceptTermsConditions: false,
                sosReceive: true,
            }}
            onSubmit={onSignUp}
            validationSchema={validationSchema}
        >
            <Form colon={false} size="large">
                <Form.Item name="name">
                    <Input autoFocus name="name" placeholder="Name" />
                </Form.Item>

                <Form.Item name="email">
                    <Input name="email" placeholder="Email" />
                </Form.Item>

                <Form.Item name="mobile">
                    <Input name="mobile" placeholder="Mobile" />
                </Form.Item>

                <Form.Item name="location">
                    <LocationInput name="location" placeholder="Location" />
                </Form.Item>

                <Form.Item name="password">
                    <Input.Password name="password" placeholder="Password" />
                </Form.Item>

                <Form.Item name="passwordConfirm">
                    <Input.Password name="passwordConfirm" placeholder="Re-enter your password" />
                </Form.Item>

                <Form.Item label="Receive SOS requests" name="sosReceive">
                    <Switch name="sosReceive" />
                </Form.Item>

                <Form.Item name>
                    <Alert description="Receiving SOS requests helps other members and builds a stronger community" icon={<HeartTwoTone twoToneColor="#eb2f96" />} message="Help the Skiggle community" showIcon type="none" />
                </Form.Item>

                <Form.Item name>
                    <Checkbox name="acceptPrivacyPolicy">
                        I have read and understand the processing of my personal data as described in the{' '}
                        <a href="https://www.skiggle.co.uk/privacy-policy" rel="noopener noreferrer" target="_blank">
                            Privacy Policy
                        </a>
                    </Checkbox>
                </Form.Item>

                <Form.Item name>
                    <Checkbox name="acceptTermsConditions">
                        I have read and accept the{' '}
                        <a href="https://www.skiggle.co.uk/terms-of-use/terms-and-conditions" rel="noopener noreferrer" target="_blank">
                            Terms & Conditions
                        </a>
                    </Checkbox>
                </Form.Item>
                {errorMessage ? (
                    <Form.Item name>
                        <Alert description={errorMessage} message="Something went wrong" showIcon type="error" />
                    </Form.Item>
                ) : null}
                <Form.Item name>
                    <SubmitButton block>Sign up</SubmitButton>
                </Form.Item>
            </Form>
        </Formik>
    </PageCentredFullScreen>
);

SignUpDetailsView.propTypes = {
    onSignUp: func.isRequired,
};

export { SignUpDetailsView };
