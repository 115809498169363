import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { object, string } from 'yup';

import { Card } from '@/components/atoms';

type Props = {
    initialValues: Values;
    onSubmit: (values: Values) => void;
};

type Values = {
    message: string;
};

const validationSchema = object().shape({
    message: string().max(160, 'Message must be 160 characters or less').required('Please enter a description'),
});

const SosItemInputs = ({ initialValues, onSubmit }: Props) => {
    const handleSubmit = (values: Values) => {
        onSubmit(values);
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form colon={false} layout="vertical" size="large">
                    <Card title="Details">
                        <Form.Item label="Give a brief description of your request, including the quantity you require" name="message">
                            <Form.Item name="" noStyle>
                                <Input.TextArea autoSize={{ minRows: 3 }} fast name="message" />
                            </Form.Item>
                        </Form.Item>
                    </Card>

                    <Form.Item name="controls">
                        <SubmitButton block>Resubmit</SubmitButton>
                    </Form.Item>
                </Form>
            </Formik>
        </>
    );
};

export { SosItemInputs };
