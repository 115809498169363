import { gql, useMutation } from '@apollo/client';

const useLogoutMutation = (options) =>
    useMutation(
        gql`
            mutation LogoutMutation {
                logout
            }
        `,
        {
            update: (cache) =>
                cache.writeQuery({
                    query: gql`
                        query MeQuery {
                            me
                        }
                    `,
                    data: { me: null },
                }),
            ...options,
        }
    );

export { useLogoutMutation };
