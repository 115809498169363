import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

import styles from './HeaderView.module.css';
import logoImage from './logo.svg';

const HeaderView = ({ isAdmin, isAuthenticated, onLogout, userData }) => {
    const location = useLocation();
    const [showCompactMenu, setShowCompactMenu] = useState(false);
    const isCompact = useMediaQuery({ maxWidth: 1168 });
    const isMobile = useMediaQuery({ maxWidth: 568 });
    const subMenuClassName = isCompact ? null : styles.right;

    useEffect(() => {
        setShowCompactMenu(false);
    }, [location]);

    useEffect(() => {
        if (userData && userData.isBanned) {
            const modal = Modal.warning();
            updateModalText(modal, 7);
            setTimeout(() => {
                onLogout();
            }, 7000);
        }
    }, [userData]);

    const handleToggleCompactMenu = () => setShowCompactMenu(!showCompactMenu);

    const updateModalText = (modal, count) => {
        if (count >= 1) {
            modal.update({
                title: 'Warning',
                okButtonProps: { className: 'hideBannedOkBtn' },
                content: `You are blocked by Admin. We are logging you out of the system. Please contact through email for further details! (${count})`,
            });
            setTimeout(() => {
                updateModalText(modal, count - 1);
            }, 1000);
        }
    };

    const adminMenu =
        isAuthenticated && isAdmin ? (
            <Menu.SubMenu className={subMenuClassName} popupClassName={styles.popupContainer} popupOffset={[-20, 0]} title="Admin">
                <Menu.Item>
                    <Link to="/sos/pending">Pending SOS Requests</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/marketplace/pending">Pending Marketplace Items</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/admin/product-enquiries">Product Enquiries Statistics</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/admin/users-list">Users List</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/admin/users">User Statistics</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/admin/user-sos-statestics">User SOS Statistics</Link>
                </Menu.Item>

                <Menu.Item>
                    <Link to="/admin/sos">SOS Statistics</Link>
                </Menu.Item>
            </Menu.SubMenu>
        ) : null;

    const userMenu = isAuthenticated ? (
        <Menu.SubMenu className={subMenuClassName} popupClassName={styles.popupContainer} popupOffset={[-20, 0]} title="Manage Account">
            <Menu.Item>
                <Link to="/profile">My Profile</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/marketplace/my-items">My Items</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/sos/my-sos">My SOS</Link>
            </Menu.Item>
            <Menu.Item onClick={onLogout}>Log Out</Menu.Item>
        </Menu.SubMenu>
    ) : (
        <Menu.Item className={styles.right}>
            <Link to="/login">Sign in / Register</Link>
        </Menu.Item>
    );

    const DonateButton = (
        <a className={`${styles.donateButtonLink}`} href="https://www.paypal.com/donate/?hosted_button_id=36GXB2BW2YP2U" rel="noreferrer" target="_Blank">
            Donate
        </a>
    );

    const menuContents = (
        <>
            {userMenu}
            {adminMenu}
            <Menu.Item className={styles.sos}>
                <Link to="/sos/new">SOS</Link>
            </Menu.Item>
            <Menu.Item className={styles.right}>
                <Link to="/marketplace">Marketplace</Link>
            </Menu.Item>
            <Menu.Item className={styles.right}>
                <a href="https://www.skiggle.co.uk/support-us">Support Us</a>
            </Menu.Item>
            <Menu.Item className={styles.right}>
                <a href="https://www.skiggle.co.uk/what-we-do">What We Do</a>
            </Menu.Item>

            <Menu.SubMenu className={subMenuClassName} popupClassName={styles.popupContainer} popupOffset={[-20, 0]} title="About Us">
                <Menu.Item>
                    <a href="https://www.skiggle.co.uk/about-us/our-story">Our Story</a>
                </Menu.Item>
                <Menu.Item>
                    <a href="https://www.skiggle.co.uk/about-us/meet-the-team">Meet The Team</a>
                </Menu.Item>
                <Menu.Item>
                    <a href="https://www.skiggle.co.uk/about-us/faqs">FAQs</a>
                </Menu.Item>
                <Menu.Item>
                    <a href="https://www.skiggle.co.uk/blog">Blog</a>
                </Menu.Item>
                <Menu.Item>
                    <a href="https://www.skiggle.co.uk/member-reviews">Member Reviews</a>
                </Menu.Item>
            </Menu.SubMenu>
        </>
    );

    return (
        <Layout.Header className={styles.container}>
            <Menu className={styles.antMenuOverflow} mode="horizontal" theme="dark">
                <Menu.Item className={styles.logoMenuItem} key="/">
                    <a href="https://www.skiggle.co.uk">
                        <img alt="Logo" className={styles.logo} src={logoImage} />
                    </a>
                </Menu.Item>

                {isCompact && <Menu.Item className={`${styles.right}`} icon={<MenuOutlined />} onClick={handleToggleCompactMenu} />}

                {!isCompact && menuContents}
                {!isCompact && <Menu.Item className={`${styles.donateButtonContainer}`}>{DonateButton}</Menu.Item>}
            </Menu>
            {isCompact ? <div className={styles.floatingDonateButton}>{DonateButton}</div> : ''}
            {isCompact && (
                <Menu hidden={!showCompactMenu} mode="inline" theme="dark">
                    {menuContents}
                </Menu>
            )}
        </Layout.Header>
    );
};

export { HeaderView };
