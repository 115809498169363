import { gql, useMutation } from '@apollo/client';

const useFavouriteMarketplaceItemMutation = (options) =>
    useMutation(
        gql`
            mutation FavouriteMarketplaceItemMutation($id: ID!) {
                favouriteMarketplaceItem(id: $id) {
                    favouriteMarketplaceItems {
                        id
                    }
                    id
                }
            }
        `,
        options
    );

export { useFavouriteMarketplaceItemMutation };
