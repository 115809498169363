import { CalendarOutlined } from '@ant-design/icons';
import { Col, Row, Space, Statistic } from 'antd';

import { Card } from '@/components/atoms';

const AdminUsersListToday = ({ total }) => (
    <Row gutter={[0, 32]}>
        <Col span={24}>
            <Card
                bodyStyle={{ display: 'none' }}
                extra={<Statistic title="Total" value={total} />}
                title={
                    <Space>
                        <CalendarOutlined /> Today
                    </Space>
                }
            />
        </Col>
    </Row>
);

export { AdminUsersListToday };
