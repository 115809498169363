import { Alert, Button } from 'antd';
import { Link } from 'react-router-dom';

import { PageCentredFullScreen } from '@/components/templates';

type Props = {
    loading: boolean;
    success: boolean;
};

const SosSurveyView = ({ loading, success }: Props) => {
    const content = loading ? (
        <Alert description="We are submitting your SOS survey..." message="Please wait" showIcon type="info" />
    ) : success ? (
        <Alert description="Thank you for answering the SOS survey. You can safely close this window." message="SOS Survey Submitted!" showIcon type="success" />
    ) : (
        <Alert description="We were unable to submit your SOS survey. Please try again later." message="Something went wrong" showIcon type="error" />
    );

    return (
        // @ts-ignore
        <PageCentredFullScreen title="SOS Survey">
            {content}
            <Link to="/login">
                <Button block type="link">
                    Go to Login
                </Button>
            </Link>
        </PageCentredFullScreen>
    );
};

export { SosSurveyView };
