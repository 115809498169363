import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useUserEnquiriesQuery = () =>
    useQuery(
        gql`
            query {
                userEnquiries {
                    user_id
                    name
                    email
                    total_product_enquiries
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
        }
    );

export { useUserEnquiriesQuery };
