import { gql, useMutation } from '@apollo/client';

const useVerifyEmailMutation = (options) =>
    useMutation(
        gql`
            mutation VerifyEmailMutation($token: String!) {
                verifyEmail(token: $token)
            }
        `,
        {
            ...options,
        }
    );

export { useVerifyEmailMutation };
