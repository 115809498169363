import { useState } from 'react';

import { MarketplaceItemStatus } from '@/graphql';
import { useQuery } from '@/hooks';

import { MarketplaceListResults, MarketplaceListSearchBar } from './components';
import { useMarketplaceListQuery } from './hooks';
import { MarketplaceListView } from './MarketplaceListView';

const MarketplaceListContainer = () => {
    const query = useQuery();
    const [search, setSearch] = useState({});
    const { data, loading } = useMarketplaceListQuery({ status: MarketplaceItemStatus.Approved, ...search });

    const page = parseInt(query.get('page'));
    const pageSize = parseInt(query.get('pageSize'));
    const marketplaceItems = data ? data.publicMarketplaceItems : [];

    return (
        <MarketplaceListView>
            {{
                results: <MarketplaceListResults initialPage={page} initialPageSize={pageSize} loading={loading} marketplaceItems={marketplaceItems} />,
                searchBar: <MarketplaceListSearchBar numberOfMatches={marketplaceItems.length} onSearch={setSearch} />,
            }}
        </MarketplaceListView>
    );
};

export { MarketplaceListContainer };
