import { EditOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';

import { MarketplaceNewDetails, MarketplaceNewNotice, MarketplaceNewPreview } from './components';
import { MarketplaceNewContainerValues } from './MarketplaceNewContainer';
import styles from './MarketplaceNewView.module.css';

type Props = {
    onBack: () => void;
    onSubmit: () => Promise<void>;
    setStep: (step: number) => void;
    setValues: (values: MarketplaceNewContainerValues) => void;
    step: number;
    values: MarketplaceNewContainerValues;
};

const MarketplaceNewView = ({ onBack, onSubmit, setStep, setValues, step, values }: Props) => {
    const scrollToTop = () => window.scroll(0, 0);
    const handleDetailsNext = (values: MarketplaceNewContainerValues) => {
        setValues(values);
        scrollToTop();
        setStep(step + 1);
    };
    const handleNoticeNext = () => {
        scrollToTop();
        setStep(step + 1);
    };
    const handleBack = () => {
        scrollToTop();
        setStep(step - 1);
    };

    return (
        <>
            <PageHeader icon={{ src: MarketplaceIcon }} onBack={onBack} title="New Item" />
            <Layout>
                <Row className={styles.stepsRow} gutter={[0, 32]}>
                    <Col span={24}>
                        <Steps current={step}>
                            <Steps.Step icon={<InfoCircleOutlined />} title="Notice" />
                            <Steps.Step icon={<EditOutlined />} title="Details" />
                            <Steps.Step icon={<EyeOutlined />} title="Preview" />
                        </Steps>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Card>
                            {step === 0 && <MarketplaceNewNotice onNext={handleNoticeNext} />}
                            {step === 1 && <MarketplaceNewDetails initialValues={values} onBack={handleBack} onNext={handleDetailsNext} />}
                            {step === 2 && <MarketplaceNewPreview onBack={handleBack} onSubmit={onSubmit} values={values} />}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        </>
    );
};

export { MarketplaceNewView };
