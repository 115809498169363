import { gql, useMutation } from '@apollo/client';

const useLoginMutation = (options) =>
    useMutation(
        gql`
            mutation LoginMutation($email: String!, $password: String!, $visitor_id: String!, $ip: String!, $browser_name: String!, $postal_code: String!, $city: String!, $country: String!) {
                login(email: $email, password: $password, visitor_id: $visitor_id, ip: $ip, browser_name: $browser_name, postal_code: $postal_code, city: $city, country: $country) {
                    id
                    name
                    email
                }
            }
        `,
        {
            update: (cache, { data: { login } }) =>
                cache.writeQuery({
                    query: gql`
                        query MeQuery {
                            me {
                                id
                                name
                                email
                            }
                        }
                    `,
                    data: { me: login },
                }),
            ...options,
        }
    );

export { useLoginMutation };
