import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useGetUsersListQuery = (search) =>
    useQuery(
        gql`
            query UsersListQuery($filter: UserFilterInput) {
                getUsersList(filter: $filter) {
                    id
                    name
                    email
                    status
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
            variables: { filter: search },
        }
    );

export { useGetUsersListQuery };
