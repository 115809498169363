import { gql, useMutation } from '@apollo/client';

const useRemoveFavouriteMarketplaceItemMutation = (options) =>
    useMutation(
        gql`
            mutation RemoveFavouriteMarketplaceItemMutation($id: ID!) {
                removeFavouriteMarketplaceItem(id: $id) {
                    favouriteMarketplaceItems {
                        id
                    }
                    id
                }
            }
        `,
        options
    );

export { useRemoveFavouriteMarketplaceItemMutation };
