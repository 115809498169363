import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSosPendingContainerSubmitSosSurveyMutation } from '@/graphql';

import { SosSurveyView } from './SosSurveyView';

const SosSurveyContainer = () => {
    // @ts-ignore
    const { feedback, token } = useParams();
    const [success, setSuccess] = useState(false);
    const [submitSosSurvey, { loading }] = useSosPendingContainerSubmitSosSurveyMutation({
        onCompleted: ({ submitSosSurvey }) => {
            setSuccess(submitSosSurvey);
        },
    });

    useEffect(() => {
        submitSosSurvey({ variables: { feedback: feedback === 'true', token } });
    }, [feedback, token, submitSosSurvey]);

    return <SosSurveyView loading={loading} success={success} />;
};

export { SosSurveyContainer };
