import { useState } from 'react';

import { withErrorsFeedback } from '@/forms';

import { ForgotPasswordView } from './ForgotPasswordView';
import { useForgotPasswordMutation } from './hooks';

const ForgotPasswordContainer = () => {
    const [success, setSuccess] = useState(false);
    const [forgotPassword] = useForgotPasswordMutation({
        onCompleted: () => {
            setSuccess(true);
        },
    });

    const handleSubmit = withErrorsFeedback(async ({ email }) => {
        await forgotPassword({ variables: { email } });
    });

    return <ForgotPasswordView onSubmit={handleSubmit} success={success} />;
};

export { ForgotPasswordContainer };
