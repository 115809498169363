import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';

type LatLng = {
    lat: number;
    lng: number;
};

type Props = {
    locations: LatLng[];
};

const Map = withGoogleMap<Props>(({ locations }) => {
    return (
        <GoogleMap
            center={{ lat: 54.7781, lng: -3.436 }}
            defaultOptions={{
                mapTypeControl: false,
                streetViewControl: false,
            }}
            defaultZoom={5}
        >
            {locations.map((location) => (
                <Marker key={`${location.lat}-${location.lng}`} position={location} />
            ))}
        </GoogleMap>
    );
});

export { Map };
