import 'yup-phone';

import { Alert, Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').max(255, 'Email must be 255 characters or less').required('Please enter a email'),
});

const ForgotPasswordView = ({ success, onSubmit }) => {
    const content = success ? (
        <Alert description="A link to reset your password has been emailed to you. You can safely close this window." message="Success!" showIcon type="success" />
    ) : (
        <Formik initialValues={{}} onSubmit={onSubmit} validationSchema={validationSchema}>
            <Form colon={false} size="large">
                <Form.Item name="email">
                    <Input autoFocus name="email" placeholder="Email" />
                </Form.Item>

                <Form.Item name>
                    <SubmitButton block>Email link</SubmitButton>
                </Form.Item>

                <Link to="/login">
                    <Button block type="link">
                        Back to Login
                    </Button>
                </Link>
            </Form>
        </Formik>
    );

    return (
        <PageCentredFullScreen description="Provide your email address and we'll email you a link to reset your password." title="Forgot password">
            {content}
        </PageCentredFullScreen>
    );
};

ForgotPasswordView.propTypes = {
    onSubmit: func.isRequired,
    success: bool,
};

export { ForgotPasswordView };
