import { Col, Row } from 'antd';

import styles from './LayoutNarrowView.module.css';

const LayoutNarrowView = ({ children }) => {
    const gutterProps = { lg: 6, md: 5, sm: 4, xl: 7, xs: 0, xxl: 8 };
    const contentProps = { lg: 12, md: 14, sm: 16, xl: 10, xs: 24, xxl: 8 };

    return (
        <Row className={styles.row}>
            <Col {...gutterProps} />
            <Col {...contentProps}>{children}</Col>
            <Col {...gutterProps} />
        </Row>
    );
};

export { LayoutNarrowView };
