import { gql, useMutation } from '@apollo/client';

const useResetPasswordMutation = (options) =>
    useMutation(
        gql`
            mutation ResetPasswordMutation($token: String!, $password: String!) {
                resetPassword(token: $token, password: $password)
            }
        `,
        {
            ...options,
        }
    );

export { useResetPasswordMutation };
