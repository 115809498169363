import { UsergroupAddOutlined } from '@ant-design/icons';

import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';

import { AdminUsersList12Months, AdminUsersList30Days, AdminUsersListOverview, AdminUsersListToday } from './components';

const AdminUsersListLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ icon: <UsergroupAddOutlined />, style: { color: 'black', backgroundColor: 'white' } }} onBack={onBack} title="Users Statistics" />
        <Layout>{children}</Layout>
    </>
);

const AdminUsersListData = ({ data }) => {
    const totalOverview = data.usersCount;
    const todayTotal = data.usersCountDays[data.usersCountDays.length - 1];
    const total30Days = data.usersCountDays.reduce((currentCount, dayCount) => currentCount + dayCount);
    const total12Months = data.usersCountMonths.reduce((currentCount, monthCount) => currentCount + monthCount);

    return (
        <AdminUsersListLayout>
            <AdminUsersListOverview total={totalOverview} />
            <AdminUsersListToday total={todayTotal} />
            <AdminUsersList30Days total={total30Days} values={data.usersCountDays} />
            <AdminUsersList12Months total={total12Months} values={data.usersCountMonths} />
        </AdminUsersListLayout>
    );
};

const AdminUsersListView = {
    Error: ({ onBack, ...props }) => (
        <AdminUsersListLayout onBack={onBack}>
            <PageError {...props} />
        </AdminUsersListLayout>
    ),
    Data: AdminUsersListData,
    Loading: ({ onBack }) => (
        <AdminUsersListLayout onBack={onBack}>
            <PageLoading />
        </AdminUsersListLayout>
    ),
};

export { AdminUsersListView };
