import { transformErrors } from '../graphql';

const withErrorsFeedback = (callback) => async (values, formikBag) => {
    if (!formikBag) console.warn('#withErrorsFeedback was not passed formikBag. If you are using a custom form #onSubmit handler, ensure formikBag is passed correctly.');
    try {
        await callback(values, formikBag);
    } catch ({ graphQLErrors }) {
        formikBag.setErrors(transformErrors(graphQLErrors));
    }
};

export { withErrorsFeedback };
