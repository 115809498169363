import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useVerifyEmailMutation } from './hooks';
import { VerifyEmailView } from './VerifyEmailView';

const VerifyEmailContainer = () => {
    const { token } = useParams();
    const [success, setSuccess] = useState(false);
    const [verifyEmail, { loading }] = useVerifyEmailMutation({
        onCompleted: ({ verifyEmail }) => {
            setSuccess(verifyEmail);
        },
    });

    useEffect(() => {
        verifyEmail({ variables: { token } });
    }, [token, verifyEmail]);

    return <VerifyEmailView loading={loading} success={success} />;
};

export { VerifyEmailContainer };
