import { Redirect, Route, useLocation } from 'react-router-dom';

import { Spinner } from '@/components/atoms';
import { LayoutCentred } from '@/components/templates';
import { useMeQuery } from '@/hooks';

const AuthenticatedRoute = ({ role, ...props }) => {
    const location = useLocation();
    const { data, error, loading } = useMeQuery();

    if (loading)
        return (
            <LayoutCentred>
                <Spinner />
            </LayoutCentred>
        );
    if (error) return <h1>{error.message}</h1>;

    const isAuthenticated = data && data.me;
    if (isAuthenticated) {
        const isLocked = data.me.locked;
        if (isLocked) {
            return <Redirect to="/locked" />;
        } else {
            const hasRole = role ? data.me.roles.find(({ type }) => type.toLowerCase() === role.toLowerCase()) : true;
            if (hasRole) {
                return <Route {...props} />;
            } else {
                return <Redirect to="/" />;
            }
        }
    } else {
        return <Redirect to={{ pathname: '/login', search: `?to=${location.pathname}` }} />;
    }
};

export { AuthenticatedRoute };
