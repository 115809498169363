import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import * as loadImage from 'blueimp-load-image';
import { Field } from 'formik-antd';
import { forwardRef, useState } from 'react';

import styles from './AvatarFormItem.module.css';

const getImageSource = (currentSource, previewSource) => {
    if (previewSource) {
        return previewSource;
    } else {
        if (currentSource && currentSource.url) {
            return currentSource.url;
        }
    }
};

const AvatarFormItem = forwardRef(({ name, validate, fast }, ref) => {
    const [previewSource, setPreviewSource] = useState();

    const handleBeforeUpload = (file) => {
        loadImage(file, (image) => setPreviewSource(image.toDataURL()), {
            canvas: true,
            orientation: true,
        });

        return false;
    };

    return (
        <Field fast={fast} name={name} validate={validate}>
            {({ field: { value, onChange } }) => {
                const imageSource = getImageSource(value, previewSource);
                const content = imageSource ? (
                    <img alt="Avatar" className={styles.image} src={imageSource} />
                ) : (
                    <div className={styles.inner}>
                        <PlusOutlined className={styles.plus} />
                    </div>
                );

                return (
                    <Upload accept=".jpg" beforeUpload={handleBeforeUpload} className={styles.container} listType="picture" name={name} onChange={(value) => onChange({ target: { value, name } })} showUploadList={false}>
                        {content}
                    </Upload>
                );
            }}
        </Field>
    );
});

export { AvatarFormItem };
