import 'yup-phone';

import * as Yup from 'yup';

const mobile = Yup.string()
    .max(255, 'Mobile must be 255 characters or less')
    .phone('GB', true, 'Please enter a valid UK mobile')
    .required('Please enter a mobile')
    .test('startsWith', 'Mobile must start with 07', (value) => `${value}`.startsWith('07'));

export { mobile };
