import { Button, Result } from 'antd';

import { LayoutCentred } from '@/components/templates';

const GlobalError = () => (
    <LayoutCentred>
        <Result
            extra={
                <a href="https://www.skiggle.co.uk">
                    <Button type="primary">Back to Skiggle</Button>
                </a>
            }
            status="500"
            subTitle="Our team has been notified."
            title="Oops! Something went wrong."
        />
    </LayoutCentred>
);

export { GlobalError };
