const { useQuery } = require('@/hooks');
const { useSosMessagesQuery } = require('./hooks');
const { SosMessagesView } = require('./SosMessagesView');
const { SosMessagesResults } = require('./components');

const SosMessagesContainer = () => {
    const query = useQuery();
    const user_sos_messages = useSosMessagesQuery();
    const page = parseInt(query.get('page'));
    const pageSize = parseInt(query.get('pageSize'));
    const user_sos_messages_data = user_sos_messages.data ? JSON.parse(JSON.stringify(user_sos_messages.data.sosUserMessages)) : [];
    return (
        <SosMessagesView>
            {{
                userResults: <SosMessagesResults initialPage={page} initialPageSize={pageSize} user_sos_messages_data={user_sos_messages_data} user_sos_messages_loading={user_sos_messages.loading} />,
            }}
        </SosMessagesView>
    );
};

export { SosMessagesContainer };
