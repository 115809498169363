import { SosDetailsModal } from '@/components/atoms';
const { Modal } = require('antd');
const { Table } = require('antd');
const moment = require('moment');
const { useState } = require('react');
const { useHistory } = require('react-router-dom');
const { useUsersByIdMessagesQuery } = require('./hooks');
const SosMessagesResults = ({ user_sos_messages_data, user_sos_messages_loading }) => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [messages, setMessages] = useState([172]);
    const users_id_arr = [];
    user_sos_messages_data.forEach((data) => {
        users_id_arr.push(data.user_id);
    });

    const userMessages = useUsersByIdMessagesQuery(users_id_arr);
    const data = userMessages.data ? userMessages.data.sosGetAllByUserIdMessages : [];

    const showModal = (e) => {
        const res = JSON.parse(e.currentTarget.getAttribute('data-column'));
        const filter_data = data.filter((message) => message.user_id == res.user_id);
        setMessages(filter_data);

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Total User Messages',
            dataIndex: 'total_messages',
        },
        {
            title: 'Details',
            dataIndex: '',
            render: (text, record) => (
                <>
                    <a data-column={JSON.stringify(record)} onClick={showModal} value={record}>
                        Details
                    </a>
                    <SosDetailsModal handleCancel={handleCancel} isModalVisible={isModalVisible} messages={messages} page="sos" />
                </>
            ),
        },
    ];
    return <Table bordered columns={columns} dataSource={user_sos_messages_data} loading={user_sos_messages_loading} pagination={false} rowKey="user_id" title={() => 'Top 10 Users'} />;
};

export { SosMessagesResults };
