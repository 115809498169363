import { Button, Result, Row } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundView = () => (
    <Row align="middle" justify="center" style={{ minHeight: '100vh' }} type="flex">
        <Result
            extra={
                <Link to="/">
                    <Button type="primary">Back Home</Button>
                </Link>
            }
            status="404"
            subTitle="Sorry, the page you visited does not exist."
            title="Page Not Found"
        />
    </Row>
);

export { NotFoundView };
