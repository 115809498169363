const { Modal } = require('antd');
const { Link } = require('antd');
const { Table } = require('antd');
const moment = require('moment');
const { useState } = require('react');
const { useHistory } = require('react-router-dom');
const { useUsersByIdEnquiriesQuery } = require('./hooks');
const UsersEnquiriesResults = ({ user_enquiries_data, user_enquiries_loading }) => {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [enquiries, setEnquiries] = useState([]);
    const users_id_arr = [];
    user_enquiries_data.forEach((data) => {
        users_id_arr.push(data.user_id);
    });
    const getFullDate = (date) => {
        return moment(date).format('DD-MM-YYYY HH:mm');
    };

    const productEnquiries = useUsersByIdEnquiriesQuery(users_id_arr);
    const data = productEnquiries.data ? productEnquiries.data.getAllByUserIdEnquiries : [];
    const showModal = (e) => {
        const res = JSON.parse(e.currentTarget.getAttribute('data-column'));
        const filter_data = data.filter((inquiry) => inquiry.user_id == res.user_id);
        setEnquiries(filter_data);

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const userTableColumns = [
        {
            title: 'ID',
            dataIndex: 'advert_id',
            key: 'advert_id',
            render: (text, record) => (
                <a href={'/marketplace/' + record.advert_id} rel="noreferrer" target="_blank">
                    {record.advert_id}
                </a>
            ),
        },
        {
            title: 'Product Details',
            render: (text, record) => (
                <div>
                    <strong>Product Title:</strong> {record.title} <br></br>
                    <strong>Product Message:</strong> {record.message}
                </div>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            minWidth: 120,
            render: (date) => getFullDate(date),
        },
    ];

    const columns = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Total Product Enquiries',
            dataIndex: 'total_product_enquiries',
            key: 'total_product_enquiries',
        },
        {
            title: 'Details',
            key: '',
            dataIndex: '',
            render: (text, record) => (
                <>
                    <a data-column={JSON.stringify(record)} onClick={showModal} value={record}>
                        Details
                    </a>
                    <Modal mask={false} okButtonProps={{ style: { display: 'none' } }} onCancel={handleCancel} title="Details" visible={isModalVisible} width={1200}>
                        <Table bordered columns={userTableColumns} dataSource={enquiries} pagination={false} rowKey="created_at" />
                    </Modal>
                </>
            ),
        },
    ];
    return <Table bordered columns={columns} dataSource={user_enquiries_data} loading={user_enquiries_loading} pagination={false} rowKey="user_id" title={() => 'Top 10 Users'} />;
};

export { UsersEnquiriesResults };
