import { CheckCircleTwoTone, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Input as AntdInput, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { bool, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { PageCentredFullScreen } from '@/components/templates';

import styles from './SignUpVerificationView.module.css';

const validationSchema = Yup.object().shape({
    code: Yup.string().length(6, 'Code must be 6 characters').required('Please enter a code'),
});

const SignUpVerificationView = ({ email, mobile, onVerifyMobile, verifyMobileSuccess }) => {
    const verifyButton = verifyMobileSuccess ? (
        <SubmitButton className={styles.verify} disabled ghost type="dashed">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
        </SubmitButton>
    ) : (
        <SubmitButton className={styles.verify} type="default">
            Verify
        </SubmitButton>
    );

    return (
        <PageCentredFullScreen description="To use all of Skiggle, verify your details." title="Verification">
            <Card className={styles.cardEmail}>
                <Card.Meta
                    avatar={<Avatar className={styles.icon} icon={<MailOutlined />} size="large" />}
                    description={
                        <>
                            A verification link as been sent to <Typography.Text strong>{email}</Typography.Text>. Please click this link to verifiy you own this email address.
                        </>
                    }
                    title="Email"
                />
            </Card>

            <Card className={styles.cardMobile}>
                <Card.Meta
                    avatar={<Avatar className={styles.icon} icon={<MobileOutlined />} size="large" />}
                    description={
                        <>
                            A verification code has been sent to <Typography.Text strong>{mobile}</Typography.Text>. Please enter the code below.
                            <Formik initialValues={{}} onSubmit={onVerifyMobile} validationSchema={validationSchema}>
                                <Form layout="inline">
                                    <Form.Item name="code">
                                        <AntdInput.Group>
                                            <Input className={styles.code} disabled={verifyMobileSuccess} name="code" placeholder="Code" />
                                            {verifyButton}
                                        </AntdInput.Group>
                                    </Form.Item>
                                </Form>
                            </Formik>
                        </>
                    }
                    title="Mobile"
                />
            </Card>

            <Link to="/login">
                <Button block className={styles.login} type="primary">
                    Login to Skiggle
                </Button>
            </Link>
        </PageCentredFullScreen>
    );
};

SignUpVerificationView.propTypes = {
    email: string.isRequired,
    mobile: string.isRequired,
    onVerifyMobile: func.isRequired,
    verifyMobileSuccess: bool.isRequired,
};

export { SignUpVerificationView };
