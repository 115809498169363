import { Modal } from 'antd';
import { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { MarketplaceItemStatus } from '@/graphql';

import { SendMessageModal } from './components';
import { useDeleteMarketplaceItemAsAdminMutation, useFavouriteMarketplaceItemMutation, useMarketplaceItemQuery, useSendMarketplaceItemContactMessageMutation, useUnfavouriteMarketplaceItemMutation } from './hooks';
import { MarketplaceShowView } from './MarketplaceShowView';

const MarketplaceShowContainer = () => {
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    const history = useHistory();
    const { data, error, loading } = useMarketplaceItemQuery({
        variables: { id },
    });
    const [sendMessage] = useSendMarketplaceItemContactMessageMutation();
    const [favouriteMarketplaceItem] = useFavouriteMarketplaceItemMutation({
        variables: { id },
    });
    const [unfavouriteMarketplaceItem] = useUnfavouriteMarketplaceItemMutation({
        variables: { id },
    });
    const [deleteMarketplaceItemAsAdmin] = useDeleteMarketplaceItemAsAdminMutation();

    const handleBack = () => history.goBack();
    const handleDelete = async (reason, feedback) => {
        await deleteMarketplaceItemAsAdmin({
            variables: { feedback, id, reason },
        });
        history.push('/marketplace');
    };
    const handleFavourite = () => {
        const isFavourite = data.me.favouriteMarketplaceItems.find((marketplaceItem) => marketplaceItem.id === data.marketplaceItem.id);

        isFavourite ? unfavouriteMarketplaceItem() : favouriteMarketplaceItem();
    };
    const handleSendMessage = () => setModalVisible(true);
    const handleModalCancel = () => setModalVisible(false);
    const handleModalSubmit = async ({ message, shareMy }, { resetForm }) => {
        await sendMessage({
            variables: {
                input: {
                    id,
                    message,
                    shareMyEmail: shareMy.includes('email'),
                    shareMyMobile: shareMy.includes('mobile'),
                    shareMyName: shareMy.includes('name'),
                },
            },
        });
        resetForm();
        setModalVisible(false);
        Modal.success({
            content: 'Your message was sent!',
        });
    };

    if (loading) return 'Loading';
    if (error) return 'Error';

    const isFavourite = data.me && data.me.favouriteMarketplaceItems.find((marketplaceItem) => marketplaceItem.id === data.marketplaceItem.id);
    const isNotFound = !data.marketplaceItem;
    const isApproved = data.marketplaceItem && data.marketplaceItem.status === MarketplaceItemStatus.Approved;

    if (isNotFound || !isApproved) return <Redirect to="/marketplace" />;

    return (
        <>
            <MarketplaceShowView isFavourite={!!isFavourite} marketplaceItem={data.marketplaceItem} onBack={handleBack} onDelete={handleDelete} onFavourite={handleFavourite} onSendMessage={handleSendMessage} />
            <SendMessageModal onCancel={handleModalCancel} onSubmit={handleModalSubmit} visible={modalVisible} />
        </>
    );
};

export { MarketplaceShowContainer };
