import { HeartFilled } from '@ant-design/icons';
import { Button, List } from 'antd';

import { Card, MarketplaceItem } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';

const MarketplaceMyFavouritesLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ icon: <HeartFilled />, style: { color: '#f56a00', backgroundColor: 'white' } }} onBack={onBack} title="My Favourites" />
        <Layout>
            <Card>{children}</Card>
        </Layout>
    </>
);

const MarketplaceMyFavouritesData = ({ data, onBack, onRemoveFavourite }) => (
    <MarketplaceMyFavouritesLayout onBack={onBack}>
        <List
            bordered={false}
            dataSource={data.me.favouriteMarketplaceItems}
            itemLayout="vertical"
            locale={{
                emptyText: 'You do not have any favourites',
            }}
            renderItem={(item) => {
                const image = item.images.length ? item.images[0].url : undefined;
                const { images, ...props } = item;

                return (
                    <MarketplaceItem
                        {...props}
                        actions={[
                            <Button danger icon={<HeartFilled />} onClick={() => onRemoveFavourite(item.id)} type="link">
                                Remove
                            </Button>,
                        ]}
                        image={image}
                    />
                );
            }}
            size="large"
        />
    </MarketplaceMyFavouritesLayout>
);

const MarketplaceMyFavouritesView = {
    Error: ({ onBack, ...props }) => (
        <MarketplaceMyFavouritesLayout onBack={onBack}>
            <PageError {...props} />
        </MarketplaceMyFavouritesLayout>
    ),
    Data: MarketplaceMyFavouritesData,
    Loading: ({ onBack }) => (
        <MarketplaceMyFavouritesLayout onBack={onBack}>
            <PageLoading />
        </MarketplaceMyFavouritesLayout>
    ),
};

export { MarketplaceMyFavouritesView };
