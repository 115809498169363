import { gql, useMutation } from '@apollo/client';

const useUnfavouriteMarketplaceItemMutation = (options) =>
    useMutation(
        gql`
            mutation UnfavouriteMarketplaceItemMutation($id: ID!) {
                unfavouriteMarketplaceItem(id: $id) {
                    favouriteMarketplaceItems {
                        id
                    }
                    id
                }
            }
        `,
        options
    );

export { useUnfavouriteMarketplaceItemMutation };
