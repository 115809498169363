import { EnvironmentOutlined } from '@ant-design/icons';
import { List } from 'antd';
import moment from 'moment';
import { ReactNode } from 'react';

import { SosStatus, User } from '@/graphql';

import styles from './SosItem.module.css';

type Props = {
    actions?: ReactNode[];
    createdAt: string;
    distance: number;
    id: string;
    message: string;
    status: SosStatus;
    user: Pick<User, 'email' | 'mobile' | 'name' | 'mySos'>;
};

const SosItem = ({ actions = [], createdAt, distance, id, message, status, user }: Props) => {
    const postedPrefix = status === SosStatus.Approved ? 'Sent' : 'Submitted';
    const postedElement = createdAt ? (
        <>
            {postedPrefix} {moment(createdAt).fromNow()}
        </>
    ) : undefined;

    return (
        <List.Item actions={[postedElement, ...actions]} className={styles.item} key={`list-item-${id}`}>
            <List.Item.Meta
                description={
                    <div>
                        <p>
                            <em>{message}</em>
                        </p>
                        {user ? (
                            <div className={styles.userDetails}>
                                <p>
                                    <b className={styles.titleText}>Created By:</b> {user.name}
                                </p>
                                <p>
                                    <b className={styles.titleText}>Email:</b> {user.email}
                                </p>
                                <p>
                                    <b className={styles.titleText}>Phone:</b> {user.mobile}
                                </p>
                            </div>
                        ) : null}
                    </div>
                }
                title={
                    <>
                        <EnvironmentOutlined /> {distance} Miles
                    </>
                }
            />
        </List.Item>
    );
};

export { SosItem };
