import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Grid, Input, Typography } from 'antd';

const { useBreakpoint } = Grid;

const UsersListSearchBarView = ({ form, numberOfMatches, onSearch, onSearchReset }) => {
    const screens = useBreakpoint();
    const verticalForm = screens.lg === false;

    return (
        <Form colon={false} form={form} layout={verticalForm ? 'vertical' : 'inline'} onFinish={onSearch} size="large">
            <Form.Item label={<SearchOutlined />} name="name" rules={[{ max: 255 }]} style={{ width: '30%' }}>
                <Input placeholder="Name" />
            </Form.Item>
            <Form.Item>
                <Button block={verticalForm} htmlType="submit" type="primary">
                    Search
                </Button>
            </Form.Item>
            <Form.Item>
                <Button block={verticalForm} onClick={onSearchReset}>
                    Reset
                </Button>
            </Form.Item>
            <Form.Item>
                <Typography.Text strong>{numberOfMatches} Matches</Typography.Text>
            </Form.Item>
        </Form>
    );
};

export { UsersListSearchBarView };
