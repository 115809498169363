import { useState } from 'react';

import { withErrorsFeedback } from '@/forms';

import { useVerifyMobileMutation } from './hooks';
import { VerifyMobileView } from './VerifyMobileView';

const VerifyMobileContainer = () => {
    const [success, setSuccess] = useState(false);
    const [verifyMobile] = useVerifyMobileMutation({
        onCompleted: ({ verifyMobile }) => {
            setSuccess(verifyMobile);
        },
    });

    const handleSubmit = withErrorsFeedback(async ({ code }) => {
        await verifyMobile({ variables: { code } });
    });

    return <VerifyMobileView onSubmit={handleSubmit} success={success} />;
};

export { VerifyMobileContainer };
