import { gql, useMutation } from '@apollo/client';

const useSignUpMutation = (options) =>
    useMutation(
        gql`
            mutation SignUpMutation($input: SignUpInput!) {
                signUp(input: $input) {
                    email
                    id
                    mobile
                    name
                }
            }
        `,
        {
            ...options,
        }
    );

export { useSignUpMutation };
