import { message } from 'antd';

import { MarketplaceItemStatus, useMarketplacePendingContainerApproveMarketplaceItemMutation, useMarketplacePendingContainerQuery, useMarketplacePendingContainerRejectMarketplaceItemMutation } from '@/graphql';

import { MarketplacePendingView } from './MarketplacePendingView';

const MarketplacePendingContainer = () => {
    const { data, error, loading } = useMarketplacePendingContainerQuery({
        variables: { filter: { status: MarketplaceItemStatus.New, isItemPaid: true } },
        fetchPolicy: 'network-only',
    });
    const [approveMarketplaceItem] = useMarketplacePendingContainerApproveMarketplaceItemMutation();
    const [rejectMarketplaceItem] = useMarketplacePendingContainerRejectMarketplaceItemMutation();

    const handleApprove = async (id: string) => {
        try {
            const { data } = await approveMarketplaceItem({ variables: { id } });
            if (!data?.approveMarketplaceItem) throw new Error();

            message.success('Marketplace Item successfully approved');
        } catch {
            message.error('Unable to approve Marketplace Item');
        }
    };

    const handleReject = async (id: string, reason: string, feedback: string) => {
        try {
            const { data } = await rejectMarketplaceItem({ variables: { feedback, id, reason } });
            if (!data?.rejectMarketplaceItem) throw new Error();

            message.success('Marketplace Item successfully rejected');
        } catch {
            message.error('Unable to reject Marketplace Item');
        }
    };

    if (error) return 'Error';

    return <MarketplacePendingView data={data} loading={loading} onApprove={handleApprove} onReject={handleReject} />;
};

export { MarketplacePendingContainer };
