import { Button, Col, Image, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { Card } from '@/components/atoms';
import { Layout } from '@/components/templates';

import facebook from './facebook.png';
import styles from './LandingView.module.css';
import marketplace from './marketplace.png';
import sos from './sos.png';

const LandingView = () => (
    <Layout>
        <Row className={styles.row} gutter={[16, 16]}>
            <Col lg={12} md={24} sm={24} xl={12} xs={24} xxl={12}>
                <Card className={`${styles.card} ${styles.left}`}>
                    <Image preview={false} src={sos} width={250} />
                    <Typography.Title className={styles.title}>EMERGENCY SOS</Typography.Title>
                    <Typography.Paragraph className={styles.paragraph}>Request emergency care-based essentials to help you on your way.</Typography.Paragraph>
                    <Link to="/sos/new">
                        <Button shape="round" size="large">
                            SEND SOS
                        </Button>
                    </Link>
                </Card>
            </Col>
            <Col lg={12} md={24} sm={24} xl={12} xs={24} xxl={12}>
                <Card className={`${styles.card} ${styles.right}`}>
                    <Image preview={false} src={marketplace} width={250} />
                    <Typography.Title className={styles.title}>MARKETPLACE</Typography.Title>
                    <Typography.Paragraph className={styles.paragraph}>Search, sell or swap surplus care-based essentials and equipment.</Typography.Paragraph>
                    <Link to="/marketplace">
                        <Button shape="round" size="large">
                            BROWSE MARKETPLACE
                        </Button>
                    </Link>
                </Card>
            </Col>
        </Row>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card className={`${styles.card} ${styles.bottom}`}>
                    <Image preview={false} src={facebook} width={50} />
                    <Typography.Title className={styles.title}>Looking for like-minded individuals?</Typography.Title>
                    <a href="https://www.facebook.com/SkiggleUK" rel="noopener noreferrer" target="_blank">
                        <Button shape="round" size="large">
                            Join our community on Facebook
                        </Button>
                    </a>
                </Card>
            </Col>
        </Row>
    </Layout>
);

export { LandingView };
