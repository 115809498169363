import { Layout } from 'antd';

import logo01 from './01.jpg';
import logo02 from './02.jpg';
import logo03 from './03.png';
import logo04 from './04.jpg';
import logo05 from './05.jpg';
import logo06 from './06.jpg';
import styles from './FooterView.module.css';

const FooterView = () => {
    return (
        <Layout.Footer className={styles.container}>
            <div className={styles.contactSection}>
                <div className={styles.contactLinksSection}>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/">
                        HOME
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/about-us/">
                        ABOUT US
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/about-us/faqs/">
                        FAQS
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/blog/">
                        BLOG
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/contact/">
                        CONTACT US
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/accessibility/">
                        ACCESSIBILITY
                    </a>
                    <a className={styles.contactLinksSectionItem} href="https://www.skiggle.co.uk/terms-of-use/">
                        TERMS OF USE
                    </a>
                </div>
                <div className={styles.contactInfoSection}>
                    <div className={styles.contactInfoTitle}>Skiggle</div>
                    <div className={styles.contactInfoDetails}>
                        <div>PO Box 1411, Preston, PR2 0YD</div>
                        <div>
                            Email:{' '}
                            <a className={styles.contactInfoDetailsEmail} href="mailto:info@skiggle.co.uk?subject=Enquiry%20from%20the%20Skiggle%20website">
                                info@skiggle.co.uk
                            </a>
                        </div>
                    </div>
                    <div className={styles.contactInfoSocial}>
                        <a className={`${styles.contactInfoSocialItem} ${styles.contactInfoSocialItemFacebook}`} href="https://www.facebook.com/SkiggleUK">
                            Follow us on Facebook
                        </a>
                        <a className={`${styles.contactInfoSocialItem} ${styles.contactInfoSocialItemTwitter}`} href="https://twitter.com/skiggleuk">
                            Follow us on Twitter
                        </a>
                        <a className={`${styles.contactInfoSocialItem} ${styles.contactInfoSocialItemLinkedIn}`} href="https://www.linkedin.com/company/skiggle">
                            Follow us on LinkedIn
                        </a>
                        <a className={`${styles.contactInfoSocialItem} ${styles.contactInfoSocialItemInstagram}`} href="https://www.instagram.com/skiggleuk/">
                            Follow us on Instagram
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.sponsorsSection}>
                <div className={styles.sponsorsSectionLogos}>
                    <a href="https://www.fundraisingregulator.org.uk">
                        <img alt="" className={styles.sponsorsSectionLogo} src={logo01} />
                    </a>
                    <a href="https://www.charityexcellence.co.uk">
                        <img alt="" className={styles.sponsorsSectionLogo} src={logo02} />
                    </a>
                    <img alt="" className={styles.sponsorsSectionLogo} src={logo03} />
                    <a href="https://www.investec.com/en_gb/intermediary-investment/investment-services-for-accountants-and-solicitors/court-of-protection.html?medium=referral&source=skiggle&campaign=uk-iwin-court-of-protection-partnership">
                        <img alt="" className={styles.sponsorsSectionLogo} src={logo04} />
                    </a>
                    <a href="https://www.hughjonessolicitors.co.uk">
                        <img alt="" className={styles.sponsorsSectionLogo} src={logo05} />
                    </a>
                    <a href="https://www.peoplejunction.co.uk">
                        <img alt="" className={styles.sponsorsSectionLogo} src={logo06} />
                    </a>
                </div>
                <div className={styles.sponsorsSectionCopyright}>© Skiggle 2021. All rights reserved. Registered charity in England & Wales (1185108), Scotland (SC050478)</div>
            </div>
        </Layout.Footer>
    );
};

export { FooterView };
