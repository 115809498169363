import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Footer, Header } from '@/components/organisms';
import { AdminSosList, AdminUsersList, Landing, MarketplaceEdit, MarketplaceList, MarketplaceMyFavourites, MarketplaceMyItems, MarketplaceNew, MarketplacePending, MarketplaceShow, NotFound, PaymentLink, ProductEnquiries, Profile, SosEdit, SosMessages, SosMySos, SosNew, SosPending, UsersList } from '@/components/pages';
import { AuthenticatedRoute, withAnalytics } from '@/routes';

import styles from './FrameView.module.css';

const FrameView = () => (
    <>
        <Header />
        <Layout>
            <Layout.Content className={styles.content}>
                <Switch>
                    <Route component={withAnalytics(Landing)} exact path="/" />

                    {/* Profile */}
                    <AuthenticatedRoute component={withAnalytics(Profile)} exact path="/profile" />

                    {/* Marketplace */}
                    <Route component={withAnalytics(MarketplaceList)} exact path="/marketplace" />
                    <AuthenticatedRoute component={withAnalytics(MarketplaceNew)} exact path="/marketplace/new" />
                    <AuthenticatedRoute component={withAnalytics(MarketplaceMyItems)} exact path="/marketplace/my-items" />
                    <AuthenticatedRoute component={withAnalytics(MarketplaceMyFavourites)} exact path="/marketplace/my-favourites" />
                    <AuthenticatedRoute component={MarketplacePending} exact path="/marketplace/pending" role="admin" />
                    <AuthenticatedRoute component={withAnalytics(MarketplaceEdit)} exact path="/marketplace/:id/edit" />
                    <Route component={withAnalytics(MarketplaceShow)} exact path="/marketplace/:id" />

                    {/* SOS */}
                    <Redirect exact from="/sos/create" to="/sos/new" />
                    <AuthenticatedRoute component={withAnalytics(SosNew)} exact path="/sos/new" />
                    <AuthenticatedRoute component={withAnalytics(SosMySos)} exact path="/sos/my-sos" />
                    <AuthenticatedRoute component={SosPending} exact path="/sos/pending" role="admin" />
                    <AuthenticatedRoute component={withAnalytics(SosEdit)} exact path="/sos/:id/edit" />

                    {/* Admin */}
                    <AuthenticatedRoute component={AdminSosList} exact path="/admin/sos" role="admin" />
                    <AuthenticatedRoute component={AdminUsersList} exact path="/admin/users" role="admin" />
                    <AuthenticatedRoute component={UsersList} exact path="/admin/users-list" role="admin" />
                    <AuthenticatedRoute component={ProductEnquiries} exact path="/admin/product-enquiries" role="admin" />
                    <AuthenticatedRoute component={SosMessages} exact path="/admin/user-sos-statestics" role="admin" />

                    {/* Other */}
                    <Route component={withAnalytics(PaymentLink)} exact path="/payment-link/:id" />
                    <Route component={withAnalytics(NotFound)} />
                </Switch>
            </Layout.Content>
        </Layout>
        <Footer />
    </>
);

export { FrameView };
