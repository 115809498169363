import { HeartFilled, PicLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Modal } from 'antd';
import { Link } from 'react-router-dom';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { BuyingSellingAdvice, Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { useAuthenticated, useMobileMediaQuery } from '@/hooks';

const handleAdviceClick = () => {
    Modal.info({
        title: BuyingSellingAdvice.TITLE,
        content: BuyingSellingAdvice.MESSAGE,
    });
};

const MarketplaceListView = ({ children }) => {
    const [isAuthenticated] = useAuthenticated();
    const isMobile = useMobileMediaQuery();

    const shape = isMobile ? 'circle' : 'round';
    const extra = isAuthenticated
        ? [
              <Link key="marketplace_my_items" to="/marketplace/my-items">
                  <Button icon={<PicLeftOutlined style={{ color: '#f97f48' }} />} shape={shape} size="large">
                      {!isMobile && 'My Items'}
                  </Button>
              </Link>,
              <Link key="marketplace_my_favourites" to="/marketplace/my-favourites">
                  <Button icon={<HeartFilled style={{ color: '#f56a00' }} />} shape={shape} size="large">
                      {!isMobile && 'My Favourites'}
                  </Button>
              </Link>,
              <Link key="marketplace_new_link" to="/marketplace/new">
                  <Button icon={<PlusOutlined />} shape="round" size="large" type="primary">
                      New Item
                  </Button>
              </Link>,
          ]
        : [];

    return (
        <>
            <PageHeader
                extra={extra}
                icon={{ src: MarketplaceIcon }}
                subTitle={
                    <Button onClick={handleAdviceClick} type="link">
                        {isMobile && 'Need advice?'}
                        {!isMobile && 'Need advice buying or selling?'}
                    </Button>
                }
                title="Marketplace"
            />
            <Layout>
                <Card>
                    {children.searchBar}
                    <Divider dashed />
                    {children.results}
                </Card>
            </Layout>
        </>
    );
};

export { MarketplaceListView };
