import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useMarketplaceListQuery = (search) =>
    useQuery(
        gql`
            query MarketplaceListQuery($filter: MarketplaceItemsFilterInput) {
                publicMarketplaceItems(filter: $filter) {
                    category {
                        id
                        name
                    }
                    createdAt
                    description
                    id
                    images {
                        id
                        uuid
                        url
                    }
                    location
                    price
                    title
                    listingDate
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
            variables: { filter: search },
        }
    );

export { useMarketplaceListQuery };
