const { message } = require('antd');
const { Table } = require('antd');
const { useHistory } = require('react-router-dom');
const ProductEnquiriesResults = ({ product_enquiries_data, product_enquiries_loading }) => {
    const history = useHistory();

    const columns = [
        {
            title: 'Advert ID',
            dataIndex: 'advert_id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            key: 'advert_id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Total Users',
            dataIndex: 'total_users',
            key: 'total_users',
        },
    ];
    return <Table bordered columns={columns} dataSource={product_enquiries_data} loading={product_enquiries_loading} pagination={false} rowKey="id" title={() => 'Top 10 Adverts'} />;
};

export { ProductEnquiriesResults };
