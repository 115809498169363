import { message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { useMarketplaceEditContainerEditMarketplaceItemMutation, useMarketplaceEditContainerQuery } from '@/graphql';
import { viewFactory } from '@/utils';

import { MarketplaceEditView } from './MarketplaceEditView';

const MarketplaceEditContainer = () => {
    const { id } = useParams();
    const history = useHistory();
    const query = useMarketplaceEditContainerQuery({ variables: { id } });
    const [mutate] = useMarketplaceEditContainerEditMarketplaceItemMutation({
        onError: (error) => message.error(error.message),
    });

    const handleBackToList = () => history.push('/marketplace/my-items');
    const handleSubmit = async (values) => {
        const { images, isFree, price, ...input } = values;
        const priceInPence = price * 100;

        await mutate({
            variables: {
                input: {
                    ...input,
                    id,
                    images: images.filter(({ __typename }) => __typename).map(({ id }) => ({ id })),
                    imagesNew: images.filter(({ originFileObj }) => originFileObj).map(({ originFileObj }) => originFileObj),
                    price: priceInPence,
                },
            },
        });

        handleBackToList();
    };

    return viewFactory(MarketplaceEditView, query, {
        onBack: handleBackToList,
        onSubmit: handleSubmit,
    });
};

export { MarketplaceEditContainer };
