import { PageHeader as PageHeaderAntDesign } from 'antd';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHeader = ({ icon, title, ...props }) => (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <PageHeaderAntDesign
            avatar={{
                size: 'large',
                ...icon,
            }}
            title={title}
            {...props}
        />
    </>
);

PageHeader.propTypes = {
    icon: object.isRequired,
};

export { PageHeader };
