import { PicLeftOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router';

import { Card } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { LayoutNarrow } from '@/components/templates';
import { SosStatus } from '@/graphql';

import { SosItemInputs } from './SosItemInputs';

const SosMyItemsLayout = ({ children, onBack, title = 'Edit' }) => (
    <>
        <PageHeader icon={{ icon: <PicLeftOutlined />, style: { color: '#f97f48', backgroundColor: 'white' } }} onBack={onBack} title={title} />
        <LayoutNarrow>
            <Card>{children}</Card>
        </LayoutNarrow>
    </>
);

const SosEditData = ({ data, onBack, onSubmit }) => {
    if (data.sosById.status !== SosStatus.Rejected) return <Redirect to="/sos/new" />;

    const initialValues = { message: data.sosById.message };

    return (
        <SosMyItemsLayout onBack={onBack} title={`Edit SOS`}>
            <Card>
                <SosItemInputs initialValues={initialValues} onSubmit={onSubmit} />
            </Card>
        </SosMyItemsLayout>
    );
};

const SosEditView = {
    Error: ({ onBack, ...props }) => (
        <SosMyItemsLayout onBack={onBack}>
            <PageError {...props} />
        </SosMyItemsLayout>
    ),
    Data: SosEditData,
    Loading: ({ onBack }) => (
        <SosMyItemsLayout onBack={onBack}>
            <PageLoading />
        </SosMyItemsLayout>
    ),
};

export { SosEditView };
