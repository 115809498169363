import { gql, useQuery } from '@apollo/client';

const useMarketplaceItemQuery = (options) =>
    useQuery(
        gql`
            query MarketplaceItemQuery($id: ID!) {
                marketplaceItem(id: $id) {
                    category {
                        id
                        name
                    }
                    description
                    id
                    images {
                        id
                        uuid
                        url
                    }
                    location
                    price
                    title
                    status
                }
                me {
                    favouriteMarketplaceItems {
                        id
                    }
                    id
                }
            }
        `,
        {
            ...options,
        }
    );

export { useMarketplaceItemQuery };
