import { gql, useMutation } from '@apollo/client';

const useResendVerifyEmailMutation = (options) =>
    useMutation(
        gql`
            mutation ResendVerifyEmailMutation {
                resendVerifyEmail
            }
        `,
        {
            ...options,
        }
    );

export { useResendVerifyEmailMutation };
