import { gql, useMutation } from '@apollo/client';

const useVerifyMobileMutation = (options) =>
    useMutation(
        gql`
            mutation VerifyMobileMutation($code: String!) {
                verifyMobile(code: $code)
            }
        `,
        {
            ...options,
        }
    );

export { useVerifyMobileMutation };
