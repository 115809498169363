import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';

const useProductEnquiriesQuery = () =>
    useQuery(
        gql`
            query {
                productEnquiries {
                    advert_id
                    title
                    description
                    total_users
                }
            }
        `,
        {
            onError: (error) => message.error(error.message),
        }
    );

export { useProductEnquiriesQuery };
