import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { PageCentredFullScreen } from '@/components/templates';

const LockedView = ({ reason }) => (
    <PageCentredFullScreen description={reason} title="Account locked">
        <Link to="/login">
            <Button block type="link">
                Back to Login
            </Button>
        </Link>
    </PageCentredFullScreen>
);

export { LockedView };
