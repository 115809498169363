import { Input, Modal, Radio } from 'antd';
import { func, string } from 'prop-types';
import { useState } from 'react';

import styles from './RejectModalView.module.css';

const REASONS = {
    1: 'Skiggle does not accept stock images, please upload a photograph of your own product',
    2: 'Please photograph and list your items separately',
    3: 'Your item might be in the wrong category',
    4: 'Skiggle does not support this product ',
    5: 'Your item(s) is in breach of our terms and conditions',
    6: 'Other',
};

const RejectModalView = ({ id, onCancel, onOk }) => {
    const [reason, setReason] = useState(undefined);
    const [feedback, setFeedback] = useState('');

    const handleCancel = () => {
        setReason(undefined);
        onCancel();
    };

    const handleOk = () => {
        setReason(undefined);
        onOk(id, REASONS[reason], feedback);
    };

    const handleOptionChange = (event) => setReason(event.target.value);
    const handleFeedbackChange = (event) => setFeedback(event.target.value);

    return (
        <Modal centered closable={false} okButtonProps={{ disabled: reason === undefined }} okText="Reject" onCancel={handleCancel} onOk={handleOk} title="Reject Marketplace Item" visible>
            <p>Please provide the reason you (the admin) are rejecting this item.</p>
            <Radio.Group onChange={handleOptionChange} value={reason}>
                <Radio className={styles.radio} value={1}>
                    {REASONS[1].split(',')[0]}
                </Radio>
                <Radio className={styles.radio} value={2}>
                    {REASONS[2]}
                </Radio>
                <Radio className={styles.radio} value={3}>
                    {REASONS[3]}
                </Radio>
                <Radio className={styles.radio} value={4}>
                    {REASONS[4]}
                </Radio>
                <Radio className={styles.radio} value={5}>
                    {REASONS[5]}
                </Radio>
                <Radio className={styles.radio} value={6}>
                    {REASONS[6]}
                </Radio>
                <Input.TextArea className={styles.feedback} onChange={handleFeedbackChange} />
            </Radio.Group>
        </Modal>
    );
};

RejectModalView.propTypes = {
    id: string.isRequired,
    onCancel: func.isRequired,
    onOk: func.isRequired,
};

export { RejectModalView };
